<template>
  <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
    <div class="me-7">
      <div
        class="symbol symbol-100px symbol-lg-200px symbol-fixed position-cneter"
      >
        <img class="" src="/images/bg/MUST_Device.PNG" alt="Device image" />
      </div>
    </div>
    <div class="flex-grow-1">
      <div
        class="d-flex justify-content-between align-items-start flex-wrap mb-2"
      >
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2">
            <div class="text-gray-800 fs-2 fw-bold me-1">
              {{ props.info.name }}
              <div
                v-if="props.info.status == '1'"
                class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
              >
                Online
              </div>
              <div
                v-else
                class="btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
              >
                Offline
              </div>
            </div>
          </div>
          <div class="d-flex fw-semobold fs-6 mb-2 pe-2 vstack">
            <div class="d-flex align-items-center me-5 mb-2">
              Location: {{ props.info.location }}
            </div>
            <div class="d-flex align-items-center me-5 mb-2">
              Installed at: {{ props.info.created_at }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from "vue";

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});
</script>
