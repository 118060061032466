<template>
  <!--begin::Row-->
  <div class="container pt-3 pb-5">
    <div class="card p-5">
      <table
        class="table align-middle table-row-dashed fs-6 gy-5"
        id="table_accounts_requests"
      >
        <thead>
          <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
            <th class="">Name</th>
            <th class="">Location</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="8">{{ $t("tips.loading") }}</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading && intersectionDevices.length == 0">
          <tr>
            <td colspan="8">{{ $t("tips.nodata") }}</td>
          </tr>
        </tbody>
        <tbody
          class="text-gray-600 fw-semibold"
          v-if="!isLoading && intersectionDevices.length > 0"
        >
          <tr
            v-for="(intersection, index) in (intersectionGroups as any)"
            :key="index"
          >
            <td>
              <!-- <router-link
                :to="{
                  name: 'intersectionDetail',
                  params: { id: intersection[0].device_id },
                }"
                >{{ index }}</router-link
              > -->
              <router-link
                :to="{
                  name: 'intersectionView',
                  params: {
                    name: intersection[0].name.split(' ').slice(0, 3).join(''),
                  },
                }"
                >{{ index }}</router-link
              >
            </td>
            <td>{{ intersection[0].location }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";

const isLoading = ref(true);
const api: any = inject("api");

const allDevices = ref([]);
const roadwayDevices: any = ref([]);
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);

const getDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      } else if (device.type === "1") {
        roadwayDevices.value.push(device);
      }
    });
    intersectionGroups.value = groupDevicesByNames(intersectionDevices.value);
    console.log("intersection Group", intersectionGroups.value);
    isLoading.value = false;
  });
};

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};

function groupDevicesByNames(devices: Device[]): { [key: string]: Device[] } {
  const deviceGroups: { [key: string]: Device[] } = {};

  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    const baseName = device.name.split(" ").slice(0, 3).join(" ");

    // If the base name is already a key in deviceGroups, append the current name
    // If not, create a new key with the current name as the initial value
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }

  return deviceGroups;
}

onMounted(() => {
  getDevices();
});
</script>
