<template>
  <div class="card p-5">
    <div class="card-header border-0 min-h-10px h-10px">
      <div class="text-muted" role="button" @click="$emit('close')">
        &lt; Back to devices list
      </div>
    </div>
    <div class="card-body" v-if="isLoading">
      <LoadingRing></LoadingRing>
    </div>
    <div class="card-body" v-else>
      <div class="row">
        <div class="col-5 border-end">
          <div class="mb-5">
            <div class="fs-8 text-muted">Device Name</div>
            <div>{{ item.name }}</div>
          </div>
          <div class="mb-5">
            <div class="fs-8 text-muted">Device ID</div>
            <div>{{ item.device_id }}</div>
          </div>
          <div>
            <div class="fs-8 text-muted">Device Created at</div>
            <div><TimeShow :date-iso-string="item.created_at" /></div>
          </div>
        </div>
        <div class="col-7">
          <div class="row mb-3">
            <div class="col-6">
              <div class="fs-8 text-muted">Device Type</div>
              <div>{{ item.type }}</div>
            </div>
            <div class="col-6">
              <div class="fs-8 text-muted">Device Status</div>
              <div>
                <span class="device-status">
                  <button
                    class="device-status-offline"
                    :class="{ 'bg-secondary': item.status == 0 }"
                    @click="changeStatus(0)"
                  >
                    {{ $t("status.device_0") }}
                  </button>
                  <button
                    class="device-status-online"
                    :class="{ 'bg-success': item.status == 1 }"
                    @click="changeStatus(1)"
                  >
                    {{ $t("status.device_1") }}
                  </button>
                  <button
                    class="device-status-disable"
                    :class="{ 'bg-warning': item.status == 2 }"
                    @click="changeStatus(2)"
                  >
                    {{ $t("status.device_2") }}
                  </button>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="fs-8 text-muted">Device Alert</div>
              <div>
                <span class="device-alert" v-if="item.alert_at != null">
                  <button class="device-alert-off" @click="trunOffAlert">
                    Trun off
                  </button>
                </span>
                <span v-else>No alert</span>
              </div>
            </div>
            <div class="col-6">
              <div class="fs-8 text-muted">Device Alert intervals</div>
              <div>
                <div class="input-group input-group-sm">
                  <input
                    type="text"
                    class="form-control"
                    v-model="intervals"
                    aria-label=""
                  />
                  <button
                    class="btn btn-secondary"
                    type="button"
                    id="updateIntervalsBtn"
                    @click="updateIntervals"
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, inject, watch } from "vue";
import LoadingRing from "@/components/LoadingRing.vue";
import TimeShow from "@/components/TimeShow.vue";

const isLoading = ref(true);
const api = inject("api");

const item = ref(null);
const intervals = ref(0);

const props = defineProps({
  detailId: { type: String, required: true },
  // item: { type: Object, required: true },
});

const emits = defineEmits<{
  (e: "changeItem", value: any): void;
}>();

onMounted(() => {
  console.log(props.detailId);
  fetchData();
});

const fetchData = async () => {
  api["devices.getSuperDeviceDetail"]({ device_id: props.detailId }).then(
    (data) => {
      console.log("data: ", data.data);
      item.value = data.data;
      intervals.value = item.value.intervals;
      isLoading.value = false;
    }
  );
};

watch(
  () => props.detailId,
  () => {
    isLoading.value = true;
    item.value = null;
    fetchData();
  }
);

const changeStatus = (status) => {
  console.log("change status: ", status);
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { status: status },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};

const trunOffAlert = () => {
  console.log("trun off alert");
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { alert_at: null },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};

const updateIntervals = () => {
  console.log("updateIntervals: ", intervals);
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { intervals: intervals.value },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};
</script>
<style scoped>
.device-status {
  border-radius: 5px;
  background: rgba(246, 246, 246, 1);
  flex-wrap: wrap;
  display: inline-block;
}
.device-status button {
  border-radius: 5px;
  border: 0;
  padding: 5px 10px;
  margin: 5px;
  background: rgba(246, 246, 246, 1);
}
/* .device-status .device-status-online.active {
  background-color: rgba(255, 255, 255, 1);
} */
</style>
