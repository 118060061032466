<template>
  <div>
    <div id="map"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";

// const center = map.getCenter();

const props = defineProps<{
  data: any;
}>();

const devices = ref(props.data);

const coordinates: any = [];

devices.value.forEach((device) => {
  coordinates.push([device.longitude, device.latitude]);
});

onMounted(() => {
  console.log("map mounted: ", devices);
  mapboxgl.accessToken =
    "pk.eyJ1IjoibHV5YW5nLWFpd2F5c2lvbiIsImEiOiJjbGFyZXhsbHkxc2IzM3JtaXZxejBzamY3In0.FbKMQRkbGUlsTw8KNkxK4Q";
  const map = new mapboxgl.Map({
    container: "map", // container ID
    style: "mapbox://styles/mapbox/streets-v12", // style URL
    zoom: 10, // starting zoom
    center: [-122.2015, 47.6101], // starting position [lng, lat]
  });

  // first coordinate in features

  const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord);
  }

  map.fitBounds(bounds, {
    padding: 50,
    maxZoom: 15,
  });

  // add zoom in/out
  const nav = new mapboxgl.NavigationControl({
    // visualizePitch: true,
  });
  map.addControl(nav, "bottom-right");

  // Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
  map.on("mouseenter", "circle", () => {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", "circle", () => {
    map.getCanvas().style.cursor = "";
  });

  // add devices markers to map
  // online device: blue
  // offline device: red
  for (var i = 0; i < devices.value.length; i++) {
    if (devices.value[i].status == 1) {
      new mapboxgl.Marker()
        .setLngLat([devices.value[i].longitude, devices.value[i].latitude])
        .setPopup(
          new mapboxgl.Popup({ offset: 25 }) // add popups
            .setHTML(
              `<h4>${devices.value[i].name}</h4> <a href="/${
                devices.value[i].type == 1 ? "devices" : "intersection"
              }/${devices.value[i].device_id}">View More</a>`
            )
        )
        .addTo(map);
    } else if (devices.value[i].status == 0) {
      new mapboxgl.Marker({ color: "red" })
        .setLngLat([devices.value[i].longitude, devices.value[i].latitude])
        .addTo(map);
    } else if (devices.value[i].status == 2) {
      new mapboxgl.Marker({ color: "gray" })
        .setLngLat([devices.value[i].longitude, devices.value[i].latitude])
        .addTo(map);
    }
  }
});
</script>

<style scoped>
#map {
  width: 100%;
  height: 700px;
}
</style>
