<template>
  <div class="row pt-3 pb-5">
    <div class="col-12" v-if="!isShowDetail && !isShowCreate">
      <div class="card p-5">
        <table
          class="table align-middle table-row-dashed fs-6 gy-5"
          id="table_accounts_requests"
        >
          <thead>
            <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
              <th class="">ID</th>
              <th class="">Name</th>
              <th class="">Email</th>
              <th class="">Created At</th>
            </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="8">{{ $t("tips.loading") }}</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && items.length == 0">
            <tr>
              <td colspan="8">{{ $t("tips.nodata") }}</td>
            </tr>
          </tbody>
          <tbody
            class="text-gray-600 fw-semibold"
            v-if="!isLoading && items.length > 0"
          >
            <tr v-for="(item, index) in items" :key="index">
              <td>{{ item.id }}</td>
              <td>
                <router-link
                  :to="{ name: 'ManageUserDetail', params: { id: item.id } }"
                  @click="showDetail(item.id)"
                  ><UserAvatar
                    :avatar="item.avatar_url"
                    :name="item.name"
                    size="50px"
                  />{{ item.name }}</router-link
                >
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-3" v-if="isShowDetail || isShowCreate">
      <div class="card card-xl-stretch mb-xl-8">
        <div class="card-header border-0">
          <h3 class="card-title fw-bold text-dark">Users</h3>
          <div class="card-toolbar">
            <!--begin::Menu-->
            <router-link
              :to="{ name: 'ManageUserDetail', params: { id: 'create' } }"
              @click="showCreate()"
              >Create</router-link
            >
            <!--end::Menu-->
          </div>
        </div>
        <div class="card-body pt-2">
          <template v-for="(item, index) in items" :key="index">
            <div
              :class="{ 'mb-7': items.length - 1 !== index }"
              class="d-flex align-items-center"
            >
              <!--begin::Avatar-->
              <div class="symbol symbol-50px me-5">
                <UserAvatar
                  :avatar="item.avatar_url"
                  :name="item.name"
                  size="50px"
                />
              </div>
              <!--end::Avatar-->

              <div class="flex-grow-1">
                <router-link
                  :to="{ name: 'ManageUserDetail', params: { id: item.id } }"
                  @click="showDetail(item.id)"
                  >{{ item.name }}</router-link
                >
                <span class="text-muted d-block fw-semobold">{{
                  item.email
                }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-9" v-if="isShowDetail">
      <UserDetailView :detailId="detailId" @close="hideDetail" />
    </div>
    <div class="col-9" v-if="isShowCreate">
      <UserCreateView @close="hideCreate" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import UserDetailView from "./UserDetail.vue";
import UserCreateView from "./UserCreate.vue";
const router = useRouter();
const isLoading = ref(true);
const api = inject("api");
const items = ref([]);
const isShowDetail = ref(false);
const isShowCreate = ref(false);
const detailId = ref(0);

const fetchData = async () => {
  api["users.getUsers"]().then((data) => {
    console.log("data: ", data.data.data);
    items.value = data.data.data;
    isLoading.value = false;
  });
};

const showDetail = (id) => {
  detailId.value = id;
  isShowCreate.value = false;
  isShowDetail.value = true;
};

const hideDetail = () => {
  isShowDetail.value = false;
  detailId.value = 0;
};

const showCreate = () => {
  isShowCreate.value = true;
  isShowDetail.value = false;
};

const hideCreate = () => {
  isShowCreate.value = false;
};

onMounted(() => {
  if (router.currentRoute.value.params.id != undefined) {
    if (router.currentRoute.value.params.id == "create") {
      isShowCreate.value = true;
    } else {
      showDetail(router.currentRoute.value.params.id);
    }
  }
  fetchData();
});
</script>
