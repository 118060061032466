import { App } from "vue";

// import { useStore } from "vuex";
import store from "@/store";

interface ICan {
  install: (app: App) => void;
  cans: (value: string[]) => boolean;
  can: (value: string) => boolean;
}

// function can(act: string): boolean {
//   console.log("can test", act);
//   return false;
// }
const Can: ICan = {
  install: function (app: App) {
    app.config.globalProperties.$can = Can.can;
    app.config.globalProperties.$cans = Can.cans;
  },
  can: function (value: string): boolean {
    return Can.cans([value]);
  },
  cans: function (value: string[]): boolean {
    // const store = useStore();
    if (value == undefined) return false;
    const user = store.state.AuthModule.user;
    if (user && user.roles !== undefined && user.email !== "") {
      if (user.roles.includes("super")) {
        return true;
      }
      const roles = user.roles;
      const permissions = user.permissions;
      for (let i = 0; i < value.length; i++) {
        const element = value[i];
        if (element === "any") {
          return true;
        }
        if (roles.includes(element)) {
          return true;
        }
        if (permissions.includes(element)) {
          return true;
        }
      }
    }
    return false;
  },
};

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $can: ICan["can"];
    $cans: ICan["cans"];
  }
}
export default Can;
