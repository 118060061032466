<template>
  <div>
    <div v-if="isLoading">
      <h1>Loading...</h1>
    </div>
    <div v-else>
      <div class="row">
        <!--===================Begin::Intersection List==============================-->
        <div class="col-3">
          <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title fw-bold text-dark">Intersection List</h3>
            </div>
            <div class="card-body pt-2">
              <template
                v-for="(item, index) in intersectionGroups"
                :key="index"
              >
                <div class="d-flex align-items-center mb-5">
                  <span class="bullet bg-success bullet-vertical h-40px"></span>
                  <div
                    class="form-check form-check-custom form-check-solid mx-5"
                  ></div>
                  <div class="flex-grow-1">
                    <router-link
                      :to="
                        '/intersection/' +
                        item[0].name.split(' ').slice(0, 3).join('')
                      "
                      class="text-gray-800 text-hover-primary fw-bold fs-6"
                      @click="showDevice(item)"
                      >{{ index }}</router-link
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <!--===================end::Intersection List==============================-->

        <!-- ==================begain::Intersection details==================-->
        <div class="col-9">
          <div class="card">
            <div class="card-body">
              <!-- ============begain::Intersection Info==================-->
              <div class="card">
                <div class="card-body pt-9 pb-0">
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center mb-2">
                      <div class="text-gray-800 fs-2 fw-bold me-1">
                        {{ currentIntersection[0].name }}
                      </div>
                      <div
                        v-if="currentIntersection[0].status == '1'"
                        class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                      >
                        Online
                      </div>
                      <div
                        v-else
                        class="btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
                      >
                        Offline
                      </div>
                    </div>
                    <div class="d-flex fw-semobold fs-6 mb-2 pe-2 vstack">
                      <div class="d-flex align-items-center me-5 mb-2">
                        Location: {{ currentIntersection[0].location }}
                      </div>
                      <div class="d-flex align-items-center me-5 mb-2">
                        Installed at:
                        {{ currentIntersection[0].created_at }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ============End::Intersection Info==================-->

              <!-- ============begain::Intersection Map==================-->
              <div class="card-body">
                <div class="col-12 mt-5 mt-md-0">
                  <IntersectionMapView
                    :data="currentIntersection"
                    :key="mapViewKey"
                  ></IntersectionMapView>
                </div>
              </div>
              <!-- ============End::Intersection Map==================-->
            </div>
          </div>
        </div>
        <!-- ==================End::Devices details  ==================-->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import IntersectionMapView from "@/views/intersection/components/IntersectionMapView.vue";

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};

const route = useRoute();
const api: any = inject("api");

const isLoading = ref(true);
const deviceName = ref(route.params.name);
const allDevices = ref([]);
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);
const currentIntersection: any = ref([]);
const mapViewKey = ref(0);

const getAllDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      }
    });
    intersectionGroups.value = groupDevicesByNames(intersectionDevices.value);
    currentIntersection.value = getCurrentIntersection(
      intersectionGroups.value
    );
    isLoading.value = false;
  });
};

function groupDevicesByNames(devices: Device[]): { [key: string]: Device[] } {
  const deviceGroups: { [key: string]: Device[] } = {};
  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    const baseName = device.name.split(" ").slice(0, 3).join(" ");
    // If the base name is already a key in deviceGroups, append the current name
    // If not, create a new key with the current name as the initial value
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }
  return deviceGroups;
}

// loop through the intersectionGroups to get the intersection that matches the deviceName
function getCurrentIntersection(intersectionGroups: any) {
  for (const intersection in intersectionGroups) {
    if (intersection.split(" ").slice(0, 3).join("") === deviceName.value) {
      return intersectionGroups[intersection];
    }
  }
}

const showDevice = async (devices: Device[]) => {
  currentIntersection.value = devices;
  mapViewKey.value += 1;
};

onMounted(async () => {
  getAllDevices();
});
</script>
