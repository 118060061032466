import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semobold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bold d-flex align-items-center fs-5" }
const _hoisted_6 = {
  href: "#",
  class: "fw-semobold text-muted text-hover-primary fs-7"
}
const _hoisted_7 = {
  class: "menu-item px-5",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "left-start",
  "data-kt-menu-flip": "center, top"
}
const _hoisted_8 = { class: "menu-title position-relative" }
const _hoisted_9 = { class: "fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "menu-sub menu-sub-dropdown w-175px py-4" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "symbol symbol-20px me-4" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_UserRole = _resolveComponent("UserRole")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_UserAvatar, {
          avatar: _ctx.user.avatar_url,
          name: _ctx.user.name,
          size: "50px",
          class: "me-3"
        }, null, 8, ["avatar", "name"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createTextVNode(_toDisplayString(_ctx.user.name) + " ", 1),
            _createVNode(_component_UserRole, {
              roles: _ctx.user.roles
            }, null, 8, ["roles"])
          ]),
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.user.email), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_link, {
        to: "/profile",
        class: "menu-link px-5"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_8, [
            _createTextVNode(" Language "),
            _createElementVNode("span", _hoisted_9, [
              _createTextVNode(_toDisplayString(_ctx.currentLangugeLocale.name) + " ", 1),
              _createElementVNode("img", {
                class: "w-15px h-15px rounded-1 ms-2",
                src: _ctx.currentLangugeLocale.flag,
                alt: "AIWaysion"
              }, null, 8, _hoisted_10)
            ])
          ])
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (lang, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "menu-item px-3",
            key: index
          }, [
            _createElementVNode("a", {
              onClick: ($event: any) => (_ctx.setLang(lang.code)),
              href: "#",
              class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage(lang.code) }])
            }, [
              _createElementVNode("span", _hoisted_13, [
                _createElementVNode("img", {
                  class: "rounded-1",
                  src: lang.flag,
                  alt: "AIWaysion"
                }, null, 8, _hoisted_14)
              ]),
              _createTextVNode(" " + _toDisplayString(lang.name), 1)
            ], 10, _hoisted_12)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("a", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.signOut())),
        class: "menu-link px-5"
      }, " Sign Out ")
    ])
  ]))
}