<template>
  <span
    class="badge fw-bold fs-8 px-2 py-1 ms-2"
    :class="badgeClass[role]"
    v-for="(role, index) in props.roles"
    :key="index"
  >
    {{ $t("roles." + role) }}
  </span>
</template>
<script lang="ts" setup>
import { ref } from "vue";
const props = defineProps({
  roles: { type: Array, required: true },
});
const badgeClass = ref({
  super: "badge-danger",
  admin: "badge-light-danger",
  manage: "badge-light-success",
  user: "badge-light-primary",
  guest: "badge-light-info",
});
</script>
