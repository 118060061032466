<template>
  <div class="traffic-signals-container">
    <div class="title">Traffic Signals</div>
    <div class="traffic-signals">
      <div v-for="(phase, index) in phases" :key="index" class="traffic-signal">
        <div class="phase-label">{{ phase.label }}</div>
        <div class="signal-lights">
          <div
            class="light"
            :class="phase.light === 'green' ? 'green' : 'off'"
          ></div>
          <div
            class="light"
            :class="phase.light === 'yellow' ? 'yellow' : 'off'"
          ></div>
          <div
            class="light"
            :class="phase.light === 'red' ? 'red' : 'off'"
          ></div>
        </div>
      </div>
    </div>
    <div class="status">Currently No Data Available</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      phases: [
        { label: "Phase 1", light: "off" },
        { label: "Phase 2", light: "off" },
        { label: "Phase 3", light: "off" },
        { label: "Phase 4", light: "off" },
        { label: "Phase 5", light: "off" },
      ],
    };
  },
};
</script>

<style scoped>
.traffic-signals-container {
  text-align: center;
  margin-top: 20px;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
}
.traffic-signals {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.traffic-signal {
  flex-grow: 1;
  text-align: center;
}
.phase-label {
  color: blue;
}
.signal-lights {
  margin-top: 5px;
}
.light {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin: 2px auto; /* Center each light */
  background-color: #333; /* Default dark color for "off" state lights */
}
.status {
  color: gray;
  font-size: 0.8rem;
  bottom: 0;
  left: 0;
  margin: 10px;
}
.green {
  background-color: #00ff00;
}
.red {
  background-color: #ff0000;
}
.yellow {
  background-color: #ffff00;
}
.off {
  background-color: #333;
}
</style>
