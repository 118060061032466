<template>
  <div class="row pt-3 pb-5">
    <div class="col-12" v-if="!isShowDetail && !isShowCreate">
      <div class="card p-5">
        <div class="card-header border-0">
          <h3 class="card-title fw-bold text-dark">Devices</h3>
          <div class="card-toolbar">
            <!--begin::Menu-->
            <router-link
              :to="{
                name: 'ManageDeviceDetail',
                params: { device_id: 'create' },
              }"
              @click="showCreate()"
              >Create</router-link
            >
            <!--end::Menu-->
          </div>
        </div>
        <div class="card-body pt-2">
          <table
            class="table align-middle table-row-dashed fs-6 gy-5"
            id="table_accounts_requests"
          >
            <thead>
              <tr
                class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
              >
                <th class="">ID</th>
                <th class="">Status</th>
                <th class="">Device Name</th>
                <th class="">Device ID</th>
                <th class="">Location</th>
                <th class="">Created At</th>
                <th class="">Action</th>
              </tr>
            </thead>
            <tbody v-if="isLoading">
              <tr>
                <td colspan="7">{{ $t("tips.loading") }}</td>
              </tr>
            </tbody>
            <tbody v-if="!isLoading && items.length == 0">
              <tr>
                <td colspan="7">{{ $t("tips.nodata") }}</td>
              </tr>
            </tbody>
            <tbody
              class="text-gray-600 fw-semibold"
              v-if="!isLoading && items.length > 0"
            >
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <StatusBadge :status="'device_' + item.status" />
                  <StatusBadge
                    :status="'alert'"
                    v-if="item.alert_at != null"
                    class="ms-3"
                  ></StatusBadge>
                </td>
                <td>
                  {{ item.name }}
                </td>
                <td>
                  <CopyBox
                    :val="item.device_id"
                    :hasAni="true"
                    :hasIcon="true"
                  />
                </td>
                <td>{{ item.location }}</td>
                <td>
                  <TimeShow :date-iso-string="item.created_at" />
                </td>
                <td>
                  <router-link
                    :to="{
                      name: 'ManageDeviceDetail',
                      params: { device_id: item.device_id },
                    }"
                    @click="showDetail(item.device_id)"
                    class="btn btn-sm btn-light btn-active-light-primary"
                  >
                    Detail
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-3" v-if="isShowDetail || isShowCreate">
      <div class="card card-xl-stretch mb-xl-8">
        <div class="card-header border-0">
          <h3 class="card-title fw-bold text-dark">Deivces</h3>
          <div class="card-toolbar">
            <!--begin::Menu-->
            <router-link
              :to="{
                name: 'ManageDeviceDetail',
                params: { device_id: 'create' },
              }"
              @click="showCreate()"
              >Create</router-link
            >
            <!--end::Menu-->
          </div>
        </div>
        <div class="card-body pt-2">
          <template v-for="(item, index) in items" :key="index">
            <div :class="{ 'mb-7': items.length - 1 !== index }">
              <div class="">
                {{ item.name }}
                <StatusBadge :status="'device_' + item.status" />
                <span
                  v-if="item.alert_at != null"
                  class="badge bg-danger fw-bold fs-8 px-2 py-1 ms-2"
                  >Alert</span
                >
              </div>
              <div class="">
                <router-link
                  :to="{
                    name: 'ManageDeviceDetail',
                    params: { device_id: item.device_id },
                  }"
                  @click="showDetail(item.device_id)"
                  >{{ item.device_id }}</router-link
                >
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="col-9" v-if="isShowDetail">
      <DeviceDetailView
        :detailId="detailId"
        @close="hideDetail"
        @changeItem="changeItem"
      />
    </div>
    <div class="col-9" v-if="isShowCreate">
      <DeviceCreateView @close="hideCreate" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import DeviceDetailView from "./DeviceDetail.vue";
import DeviceCreateView from "./DeviceCreate.vue";
import TimeShow from "@/components/TimeShow.vue";
import CopyBox from "@/components/CopyBox.vue";
import StatusBadge from "@/components/StatusBadge.vue";

const router = useRouter();
const isLoading = ref(true);
const api = inject("api");
const items = ref([]);
const isShowDetail = ref(false);
const isShowCreate = ref(false);
const detailId = ref(null);

const fetchData = async () => {
  api["devices.getAllDevices"]().then((data) => {
    console.log("data: ", data.data.data);
    items.value = data.data.data;
    isLoading.value = false;
  });
};

const showDetail = (id) => {
  detailId.value = id;
  isShowCreate.value = false;
  isShowDetail.value = true;
};

const hideDetail = () => {
  isShowDetail.value = false;
  detailId.value = null;
};

const showCreate = () => {
  isShowCreate.value = true;
  isShowDetail.value = false;
};

const hideCreate = () => {
  isShowCreate.value = false;
};

onMounted(() => {
  console.log("params: ", router.currentRoute.value.params);
  if (router.currentRoute.value.params.device_id != undefined) {
    if (router.currentRoute.value.params.device_id == "create") {
      isShowCreate.value = true;
    } else {
      showDetail(router.currentRoute.value.params.device_id);
    }
  }
  fetchData();
});

const changeItem = (item) => {
  console.log("changeItem: ", item);
  const index = items.value.findIndex((i) => i.device_id == item.device_id);
  console.log("index: ", index);
  if (index != -1) {
    items.value[index] = item;
  }
};
</script>
