<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in events" :key="event.id">
          <td>{{ event.name }}</td>
          <td>{{ event.time }}</td>
          <td><button @click="openModal(event)">Open Modal</button></td>
        </tr>
      </tbody>
    </table>

    <EventModal
      v-if="isModalOpen"
      :event="selectedEvent"
      @close="isModalOpen = false"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import EventModal from "./TucsonEventModal.vue";

const events = ref([
  {
    id: 1,
    name: "Dilemma Zone Detection",
    time: "2024-04-18 22:45:00",
    videoUrl:
      "https://storage.googleapis.com/aiwaysion-test-bucket/demo-video/tucson1_2024-04-18_22-45-04.mp4",
    videoUrl2:
      "https://storage.googleapis.com/aiwaysion-test-bucket/demo-video/tucson2_2024-04-18_22-45-03.mp4",
  },
  {
    id: 2,
    name: "Near Miss Detection",
    time: "2024-04-12 07:06:14",
    videoUrl:
      "https://storage.googleapis.com/aiwaysion-test-bucket/demo-video/tucson-near-miss-synthesized-best.mp4",
  },
]);

const isModalOpen = ref(false);
const selectedEvent = ref(null);

const openModal = (event) => {
  selectedEvent.value = event;
  isModalOpen.value = true;
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}
th {
  background-color: #f2f2f2;
}
button {
  padding: 5px 10px;
  cursor: pointer;
}
</style>
