<template>
  <img :src="src" />
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import JwtService from "@/core/services/JwtService";

const props = defineProps({
  url: { type: String, required: true },
});

const src = ref("");

const processImageUrl = (url: string) => {
  let img_src = "";
  if (url == "no-image") img_src = "/images/no-image.png";
  if (url == "no-avatar") img_src = "/images/avatar.png";
  else {
    img_src =
      process.env.VUE_APP_API_URL +
      url +
      "?access_token=" +
      JwtService.getToken();
  }
  return img_src;
};

src.value = processImageUrl(props.url);

watch(
  () => props.url,
  function () {
    src.value = processImageUrl(props.url);
  }
);
</script>
