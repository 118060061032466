<template>
  <div>
    <div v-if="isLoading">
      <h1>Loading...</h1>
    </div>
    <div>
      <div class="row">
        <!-- ==================begain::Steam List ==================-->
        <div class="col-3">
          <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title fw-bold text-dark">Devices List</h3>
            </div>
            <div class="card-body pt-2">
              <div
                class="list-container"
                style="max-height: 700px; overflow-y: auto"
              >
                <!--begin::Accordion-->
                <div class="accordion accordion-flush" id="accordionExample">
                  <template
                    v-for="(intersection, index) in intersectionGroups"
                    :key="index"
                  >
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        :id="
                          'heading' +
                          String(index).replace(/\s+/g, '').toLowerCase()
                        "
                      >
                        <button
                          class="accordion-button"
                          :class="{ collapsed: index !== 0 }"
                          type="button"
                          data-bs-toggle="collapse"
                          :data-bs-target="
                            '#collapse' +
                            String(index).replace(/\s+/g, '').toLowerCase()
                          "
                          aria-expanded="true"
                          :aria-controls="
                            'collapse' +
                            String(index).replace(/\s+/g, '').toLowerCase()
                          "
                        >
                          {{ index }}
                        </button>
                      </h2>
                      <div
                        :id="
                          'collapse' +
                          String(index).replace(/\s+/g, '').toLowerCase()
                        "
                        class="accordion-collapse collapse"
                        :class="{ show: index === 0 }"
                        :aria-labelledby="'heading' + index"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <template
                            v-for="(item, index) in intersection"
                            :key="index"
                          >
                            <div class="d-flex align-items-center mb-5">
                              <span
                                class="bullet bullet-vertical h-40px"
                                :class="{
                                  'bg-success': item.status == 1,
                                  'bg-danger': item.status == 0,
                                  'bg-second': item.status == 2,
                                }"
                              ></span>
                              <div
                                class="form-check form-check-custom form-check-solid mx-5"
                              ></div>
                              <div class="flex-grow-1">
                                <router-link
                                  :to="{
                                    name: 'intersectionDetail',
                                    params: { id: item.device_id },
                                  }"
                                  class="text-gray-800 text-hover-primary fw-bold fs-6"
                                  @click="switchDevice(item.device_id)"
                                >
                                  {{ item.name }}
                                </router-link>
                                <span class="text-muted fw-semobold d-block">{{
                                  item.updated_at
                                }}</span>
                              </div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <!--end::Accordion-->
              </div>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <!--===================end::Devices List==============================-->

        <!-- ==================begain::Devices details info ==================-->
        <div class="col-9">
          <div class="card">
            <div class="card-body">
              <div class="card mb-5 mb-xl-10">
                <div class="card-body pt-9 pb-0">
                  <!-- ===================begin::DeviceInfo=================== -->
                  <DeviceInfo :info="deviceInfo"></DeviceInfo>
                  <!-- ===================end::DeviceInfo=================== -->
                  <!--begin::Tab Nav Header-->
                  <div class="card-header card-header-stretch">
                    <ul
                      class="nav nav-stretch nav-line-tabs fw-semobold border-0"
                      role="tablist"
                      id="kt_layout_builder_tabs"
                      ref="kt_layout_builder_tabs"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 0 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="0"
                          href="#kt_builder_overview"
                          role="tab"
                        >
                          Overview
                        </a>
                      </li>

                      <li
                        class="nav-item"
                        v-if="deviceInfo.name == 'Tucson Intersection 1 NW'"
                      >
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 1 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="1"
                          href="#kt_builder_events"
                          role="tab"
                        >
                          Events
                        </a>
                      </li>

                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 2 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="2"
                          href="#kt_builder_map"
                          role="tab"
                        >
                          Map
                        </a>
                      </li>

                      <!-- <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 3 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="3"
                          href="#kt_builder_analytic"
                          role="tab"
                        >
                          Analytic
                        </a>
                      </li> -->

                      <!-- <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 4 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="4"
                          href="#kt_builder_history"
                          role="tab"
                        >
                          History
                        </a>
                      </li> -->
                    </ul>
                  </div>
                  <!--end::Tab Nav Header-->

                  <!--begin::Tab Body-->
                  <div class="card-body">
                    <div class="tab-content pt-3" id="kt_tabs">
                      <!-- begin::Overview Content-->
                      <div
                        class="tab-pane"
                        :class="{ active: tabIndex === 0 }"
                        id="kt_builder_overview"
                      >
                        <!--begain::Video-->
                        <div class="row position-relative mb-3">
                          <!--begain::VideoOverlay-->
                          <!-- <div
                            class="position-absolute"
                            style="z-index: 199; top: 0px"
                          >
                            <canvas
                              id="glcanvas"
                              width="764.684"
                              height="382.344"
                            ></canvas>
                          </div> -->
                          <!--end::VideoOverlay-->

                          <!--begain::Video-->
                          <video
                            id="mse-video"
                            autoplay
                            muted
                            playsinline
                            controls
                            style="max-width: 100%; max-height: 100%"
                          ></video>
                          <div class="center fs-4 fw-semibold d-block">
                            {{ deviceInfo.name }}
                          </div>
                        </div>
                        <!--end::Video-->

                        <!--begain:overlay switch-->
                        <!-- <div
                          class="form-check form-switch d-flex justify-content-end mb-3"
                        >
                          <label
                            class="form-check-label fw-bold fs-3 pt-1 text-gray-400"
                            for="flexSwitchCheckChecked"
                          >
                            Show Video Overlay:
                          </label>
                          <input
                            class="form-check-input ms-2 mt-2"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked
                            @click="toggleCheckbox"
                          />
                        </div> -->
                        <!--end:overlay switch-->

                        <!--begain:otherViews-->
                        <div class="row">
                          <template
                            v-for="(intersection, index) in otherViews"
                            :key="index"
                          >
                            <div class="col-lg-4">
                              <div class="card overlay overflow-hidden">
                                <div class="card-body p-0">
                                  <div class="overlay-wrapper">
                                    <AuthImage
                                      v-if="
                                        intersection.name.startsWith('Tucson')
                                      "
                                      :url="tucsonViewImages"
                                      class="w-100 rounded"
                                    />
                                    <img
                                      v-else
                                      :src="
                                        '/images/intersectionThumbnail/' +
                                        intersection.name +
                                        '.png'
                                      "
                                      class="w-100 rounded"
                                    />
                                  </div>
                                  <div
                                    class="overlay-layer bg-dark bg-opacity-25"
                                  >
                                    <router-link
                                      class="btn btn-primary btn-shadow"
                                      :to="{
                                        name: 'intersectionDetail',
                                        params: { id: intersection.device_id },
                                      }"
                                      @click="
                                        switchDevice(intersection.device_id)
                                      "
                                      >Play</router-link
                                    >
                                  </div>
                                  <div class="fs-4 fw-semibold d-block">
                                    {{ intersection.name }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </div>
                        <!--end:otherViews-->

                        <!--begain::trafficSignal-->
                        <TrafficSignal></TrafficSignal>
                        <!--end::TrafficSignal-->
                        <!--begain::trafficDetector-->
                        <TrafficDetector></TrafficDetector>
                        <!--end::TrafficDetector-->
                      </div>
                      <!--end::Overview Content -->

                      <!-- begin::EventTab Content-->
                      <div
                        class="tab-pane"
                        :class="{ active: tabIndex === 1 }"
                        id="kt_builder_events"
                      >
                        <div class="pb-5 w-50">
                          <label for="event_type_select">
                            Show By Event Type:
                          </label>
                          <select
                            id="event_type_select"
                            class="form-select"
                            aria-label="Select example"
                          >
                            <option>All</option>
                            <option value="Driving Violation">
                              Driving Violation
                            </option>
                            <option value="Pedestrian Crossing Violation">
                              Pedestrian Crossing Violation
                            </option>
                            <option value="Near Miss Event">
                              Near Miss Event
                            </option>
                            <option value="Wrong Way Driving">
                              Wrong Way Driving
                            </option>
                            <option value="Lane Compliance Violation">
                              Lane Compliance Violation
                            </option>
                          </select>
                        </div>
                        <div class="pb-5 w-50">
                          <label for="event_type_select">
                            Show Event By Time Range:
                          </label>
                          <select
                            id="event_type_select"
                            class="form-select"
                            aria-label="Select example"
                          >
                            <option>All</option>
                            <option value="Within A Day">Within A Day</option>
                            <option value="Within A Week">Within A Week</option>
                            <option value="Within A Month">
                              Within A Month
                            </option>
                            <option value="Within 6 Month">
                              Within 6 Month
                            </option>
                            <option value="Within A Year">Within A Year</option>
                          </select>
                        </div>

                        <div>
                          <!-- <EventVideo></EventVideo> -->
                          <TucsonEventTable></TucsonEventTable>
                        </div>
                      </div>
                      <!--end::EventTab content -->

                      <!--begin::Map Content -->
                      <div
                        v-if="tabIndex === 2"
                        class="col-12 mt-5 mt-md-0"
                        :class="{ active: tabIndex === 2 }"
                        id="kt_builder_map"
                      >
                        <DeviceMap :data="[deviceInfo]" />
                      </div>
                      <!--end::Map Content -->

                      <!--begin::Analytic Content -->
                      <!-- <div
                        v-if="tabIndex === 3"
                        class="col-12 mt-5 mt-md-0"
                        :class="{ active: tabIndex === 3 }"
                        id="kt_builder_analytic"
                      >
                        <ChartsWidget3></ChartsWidget3>
                      </div> -->
                      <!--end::Analytic Content -->

                      <!--begin::History Content -->
                      <!-- <div
                        v-if="tabIndex === 4"
                        class="col-12 mt-5 mt-md-0"
                        :class="{ active: tabIndex === 4 }"
                        id="kt_builder_history"
                      >
                        <TableWidget></TableWidget>
                      </div> -->
                      <!--end::History Content -->
                    </div>
                  </div>
                  <!--end::Tab Body-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ==================end::Devices details info ==================-->
      </div>
      <!-- end::-->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import DeviceInfo from "./components/DeviceInfo.vue";
import DeviceMap from "@/views/devices/DeviceMap.vue";
import StreamsDate from "./components/StreamsData";
import AuthImage from "@/components/AuthImage.vue";
import TrafficSignal from "./components/TrafficSignal.vue";
import TrafficDetector from "./components/TrafficDetector.vue";
import TucsonEventTable from "./components/TucsonEventTable.vue";
import EventVideo from "./components/TucsonEventVideo.vue";
// import { WSPusher } from "@/core/plugins/pusher";
// import { useToast } from "vue-toastification";
// import axios from "axios";
// const wsPusher: WSPusher = inject("wsPusher") as WSPusher;

const isLoading = ref(true);

const api: any = inject("api");
const tabIndex = ref(0);
const route = useRoute();
const device_id = ref(route.params.id);
const dynamicStreamUrl = ref("");
const deviceInfo = ref({});
const streamsData = StreamsDate[0];
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);
const otherViews = ref([]);
const tucsonViewImages = ref();

// const showEventType = ref("All");

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};

const groupDevicesByNames = async (devices: Device[]) => {
  let deviceGroups: { [key: string]: Device[] } = {};
  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    let baseName = device.name.split(" ").slice(0, 3).join(" ");
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }
  intersectionGroups.value = deviceGroups;
};

const getOtherView = async () => {
  let baseName = deviceInfo.value.name.split(" ").slice(0, 3).join(" ");
  otherViews.value = intersectionGroups.value[baseName].filter(
    (device: Device) => device.device_id !== device_id.value
  );
  if (baseName.startsWith("Tucson")) {
    api["devices.getDeviceCurrentImage"]({
      device_id: otherViews.value[0].device_id,
    }).then((data) => {
      tucsonViewImages.value = "/device/image/" + data.data.image_id;
    });
  }
};

const switchDevice = async (show_device_id) => {
  device_id.value = show_device_id;
  intersectionDevices.value.forEach((device) => {
    if (device.device_id === show_device_id) {
      deviceInfo.value = device;
      findDeviceUUID(deviceInfo.value.name);
      groupDevicesByNames(intersectionDevices.value);
      getOtherView();
    }
  });
  const videoEl = document.querySelector("#mse-video");
  const mseUrl = dynamicStreamUrl.value;
  startPlay(videoEl, mseUrl);
  setActiveTab({ target: { getAttribute: () => "0" } });
};

const findDeviceUUID = (deviceName: string) => {
  for (const stream in streamsData) {
    if (streamsData[stream].name === deviceName) {
      dynamicStreamUrl.value = `wss://v.aiwaysion.com/stream/${stream}/channel/0/mse`;
      return;
    }
  }
};

const getAllDevicesInfo = async () => {
  await api["devices.getAllDevicesInfo"]().then((data) => {
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      }
      if (device.device_id === device_id.value) {
        deviceInfo.value = device;
        findDeviceUUID(deviceInfo.value.name);
      }
    });
    isLoading.value = false;
  });
};

onMounted(async () => {
  await getAllDevicesInfo();
  await groupDevicesByNames(intersectionDevices.value);
  await getOtherView();
  // fetchStreamsData();
  // findDeviceUUID(deviceInfo.value.name);
  tabIndex.value = 0; //parseInt(localStorage.getItem("builderTab") || "0");
  console.log("detail  mounted: ");
  console.log("deviceinfo: ", deviceInfo.value.name.startsWith("Tucson"));
  // subscribeCount();
  // init();
  const videoEl = document.querySelector("#mse-video");
  const mseUrl = dynamicStreamUrl.value;
  console.log("video player: ", document.querySelector("#mse-video"));
  // const mseUrl = document.querySelector("#mse-url")?.value;
  startPlay(videoEl, mseUrl);
});

// close the websocket connection when the component is unmounted
// and remove all event listener
// and clea the buffer
onUnmounted(() => {
  if (ws) {
    ws.close();
  }
  if (mseSourceBuffer && mseSourceBuffer.updating) {
    mseSourceBuffer.abort();
  }
  if (mse && mse.readyState === "open") {
    mse.endOfStream();
  }
  mseSourceBuffer = null;
  mse = null;
  mseQueue.length = 0;
  mseStreamingStarted = false;
});

/**
 * Set active tab when the tab get clicked
 * @param event
 */
const setActiveTab = (event: {
  target: { getAttribute: (arg0: string) => string };
}) => {
  tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
  // keep active tab
  // localStorage.setItem("builderTab", tabIndex.value.toString());
};

const mseQueue = [];
let mseSourceBuffer: SourceBuffer;
let mseStreamingStarted = false;
let mse: MediaSource;
let ws: WebSocket;

function startPlay(videoEl: Element | null, url: string | URL) {
  // Close any existing WebSocket connection
  if (ws) {
    ws.close();
  }

  // Cleanup existing MediaSource and SourceBuffer
  if (mse && mseSourceBuffer) {
    if (mseSourceBuffer.updating) {
      mseSourceBuffer.abort();
    }
    if (mse.readyState === "open") {
      mse.endOfStream();
    }
    mseSourceBuffer = undefined;
    mse = undefined;
    mseQueue.length = 0;
    mseStreamingStarted = false;
  }

  mse = new MediaSource();
  videoEl.src = window.URL.createObjectURL(mse);
  mse.addEventListener(
    "sourceopen",
    function () {
      ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";
      ws.onmessage = function (event) {
        const data = new Uint8Array(event.data);
        if (data[0] === 9) {
          let mimeCodec: string;
          const decodedArr = data.slice(1);
          if (window.TextDecoder) {
            mimeCodec = new TextDecoder("utf-8").decode(decodedArr);
          } else {
            mimeCodec = Utf8ArrayToStr(decodedArr);
          }
          mseSourceBuffer = mse.addSourceBuffer(
            'video/mp4; codecs="' + mimeCodec + '"'
          );
          mseSourceBuffer.mode = "segments";
          mseSourceBuffer.addEventListener("updateend", pushPacket);
        } else {
          readPacket(event.data);
        }
      };
    },
    false
  );
}

function pushPacket() {
  const videoEl = document.querySelector("#mse-video");
  let packet: undefined;

  if (!videoEl) {
    // If the video element is not available, exit the function
    return;
  }

  if (!mseSourceBuffer.updating) {
    if (mseQueue.length > 0) {
      packet = mseQueue.shift();
      mseSourceBuffer.appendBuffer(packet);
    } else {
      mseStreamingStarted = false;
    }
  }
  if (videoEl && videoEl.buffered.length > 0) {
    if (typeof document.hidden !== "undefined" && document.hidden) {
      // no sound, browser paused video without sound in background
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
    }
  }
}

function readPacket(packet: any) {
  if (!mseStreamingStarted) {
    mseSourceBuffer.appendBuffer(packet);
    mseStreamingStarted = true;
    return;
  }
  mseQueue.push(packet);
  if (!mseSourceBuffer.updating) {
    pushPacket();
  }
}

function Utf8ArrayToStr(array: string | any[] | Uint8Array) {
  var out: string, i: number, len: number, c: number;
  var char2: number, char3: number;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 7:
        out += String.fromCharCode(c);
        break;
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}
</script>

<style scoped>
.env-data {
  width: 220px;
}
.env-data img {
  width: 22px;
}
</style>
