<template>
  <KTLoader v-if="loaderEnabled" :logo="loaderLogo" />

  <!-- begin:: Body -->
  <div class="page d-flex flex-row flex-column-fluid">
    <div id="kt_wrapper" class="wrapper d-flex flex-column flex-row-fluid">
      <KTHeader />

      <!-- begin:: Content Head -->
      <KTToolbar ref="toolBarAction" />
      <!-- end:: Content Head -->

      <!-- begin:: Content -->
      <div
        id="kt_content"
        class="d-flex flex-column-fluid align-items-start"
        :class="{
          'container-fluid': contentWidthFluid,
          'container-xxl': !contentWidthFluid,
        }"
      >
        <!-- begin:: Aside Left -->
        <KTAside
          v-if="asideEnabled"
          :lightLogo="themeLightLogo"
          :darkLogo="themeDarkLogo"
        />
        <!-- end:: Aside Left -->
        <!-- begin:: Content Body -->
        <div class="content flex-row-fluid">
          <router-view />
        </div>
        <!-- end:: Content Body -->
      </div>
      <!-- end:: Content -->
      <KTFooter />
    </div>
  </div>
  <!-- end:: Body -->
</template>

<script lang="ts" setup>
import { onMounted, watch, nextTick, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layouts/main-layout/aside/Aside.vue";
import KTHeader from "@/layouts/main-layout/header/Header.vue";
import KTFooter from "@/layouts/main-layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layouts/main-layout/toolbar/Toolbar.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { reinitializeComponents } from "@/core/plugins/plugins";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

const store = useStore();
const route = useRoute();
const router = useRouter();
const toolBarAction = ref(null);

store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

onMounted(() => {
  //check if current user is authenticated
  if (!store.getters.isUserAuthenticated) {
    router.push({ name: "sign-in" });
  }

  nextTick(() => {
    reinitializeComponents();
  });
  // initialize html element classes
  HtmlClass.init();
  // Simulate the delay page loading
  setTimeout(() => {
    // Remove page loader after some time
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
  }, 500);
});

watch(
  () => route.path,
  () => {
    MenuComponent.hideDropdowns(undefined);

    // check if current user is authenticated
    if (!store.getters.isUserAuthenticated) {
      router.push({ name: "sign-in" });
    }

    removeModalBackdrop();
    nextTick(() => {
      reinitializeComponents();
    });
  }
);
</script>
