console.log("Hello, client!");
import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

import Toast, { PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { createWSPusher } from "@/core/plugins/pusher";
import { createMenu } from "@/core/plugins/menu";
import createApi from "@/core/plugins/api";

import ICan from "@/core/plugins/ICan";

import Vue3VideoPlayer from "@cloudgeek/vue3-video-player";
import "@cloudgeek/vue3-video-player/dist/vue3-video-player.css";

// import VueNativeSock from "vue-native-websocket-vue3";

import "@/core/plugins/prismjs";
import PrimeVue from "primevue/config";

const app = createApp(App);
app.provide("appConfig", { sidebar: "light-sidebar" });

app.use(PrimeVue);
app.use(i18n);

const menu = createMenu();
const api = createApi();

app.use(menu);
app.use(api);
app.use(Vue3VideoPlayer, {
  lang: "zh-CN",
});

// import DevicesModule from "@/modules/devices";
// app.use(DevicesModule, { router, i18n, menu, api });

// import AnalyticsModule from "@/modules/analytics";
// app.use(AnalyticsModule, { router, i18n, menu, api });

// import MapModule from "@/modules/map";
// app.use(MapModule, { router, i18n, menu, api });

// router.removeRoute("catchAll");
// router.addRoute({
//   name: "catchAll",
//   path: "/:pathMatch(.*)*",
//   redirect: "/404",
// });

const toastOptions: PluginOptions = {
  // You can set your default options here
};
app.use(Toast, toastOptions);
app.use(ICan);
app.use(store);
app.use(router);

app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

const wsPusher = createWSPusher({
  apiKey: process.env.VUE_APP_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
  wsHost: process.env.VUE_APP_PUSHER_HOST,
  wssPort: process.env.VUE_APP_PUSHER_PORT,
  wsPort: process.env.VUE_APP_PUSHER_PORT,
  apiurl: process.env.VUE_APP_API_URL + "/broadcasting/auth",
});
wsPusher.setup(JwtService.getToken());
app.use(wsPusher);

import UserAvatar from "./components/UserAvatar.vue";
app.component("UserAvatar", UserAvatar);

import StatusBadge from "./components/StatusBadge.vue";
app.component("StatusBadge", StatusBadge);

// app.use(VueNativeSock, "wss://" + process.env.VUE_APP_PUSHER_HOST, {
//   store: store,
// });
// app.config.globalProperties.$socket = null;

app.mount("#app");

export default app;
