<template>
  <!--begin::Row-->
  <div class="container pt-3 pb-5">
    <div class="card p-5">
      <table
        class="table align-middle table-row-dashed fs-6 gy-5"
        id="table_accounts_requests"
      >
        <thead>
          <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
            <th class="">Name</th>
            <th class="">ID</th>
            <th class="">Status</th>
          </tr>
        </thead>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="8">{{ $t("tips.loading") }}</td>
          </tr>
        </tbody>
        <tbody v-if="!isLoading && devices.length == 0">
          <tr>
            <td colspan="8">{{ $t("tips.nodata") }}</td>
          </tr>
        </tbody>
        <tbody
          class="text-gray-600 fw-semibold"
          v-if="!isLoading && devices.length > 0"
        >
          <tr v-for="(device, index) in (devices as any)" :key="index">
            <td>
              <router-link
                :to="{ name: 'deviceDetail', params: { id: device.device_id } }"
                >{{ device.name }}</router-link
              >
            </td>
            <td>{{ device.device_id }}</td>
            <td v-if="device.status == '1'">
              <div
                class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
              >
                Online
              </div>
            </td>
            <td v-if="device.status == '2'">
              <div class="btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3">
                Offline
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";

const isLoading = ref(true);
const api: any = inject("api");
const devices = ref([]);

const getDevices = async () => {
  api["devices.getDevices"]().then((data) => {
    devices.value = data.data;
    isLoading.value = false;
  });
};

onMounted(() => {
  getDevices();
});
</script>
