<template>
  <!--begin::Row-->
  <div class="container pt-3 pb-5">
    <div class="row">
      <!-- Roadway Devices List -->
      <div
        :class="{
          'col-6': allDevices.length > 2,
          'col-12': intersectionDevices.length == 0,
        }"
      >
        <div class="card p-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bold fs-2x mb-3">Roadway Devices</span>
            <div class="fs-4">
              <span class="opacity-75"> You have </span>
              <span class="position-relative d-inline-block">
                <router-link
                  to="/devices"
                  class="opacity-75-hover fw-bold d-block mb-1"
                >
                  {{ roadwayDevices.length }} devices
                </router-link>
                <span
                  class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"
                ></span>
              </span>
            </div>
          </h3>
          <div class="separator separator-dashed my-5"></div>
          <div
            class="table-container"
            style="max-height: 700px; overflow-y: auto"
          >
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="table_accounts_requests"
            >
              <thead>
                <tr
                  class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="">Name</th>
                  <th class="">Location</th>
                  <th class="">Status</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr>
                  <td colspan="8">{{ $t("tips.loading") }}</td>
                </tr>
              </tbody>
              <tbody v-if="!isLoading && roadwayDevices.length == 0">
                <tr>
                  <td colspan="8">{{ $t("tips.nodata") }}</td>
                </tr>
              </tbody>
              <tbody
                class="text-gray-600 fw-semibold"
                v-if="!isLoading && roadwayDevices.length > 0"
              >
                <tr v-for="(device, index) in roadwayDevices" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'deviceDetail',
                        params: { id: device.device_id },
                      }"
                      >{{ device.name }}</router-link
                    >
                  </td>
                  <td>{{ device.location }}</td>
                  <td v-if="device.status == '1'">
                    <div
                      class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Online
                    </div>
                  </td>
                  <td v-if="device.status == '2'">
                    <div
                      class="btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Offline
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Intersection Devices List -->
      <div v-if="intersectionDevices.length > 1" class="col-6">
        <div class="card p-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bold fs-2x mb-3">Intersection Devices</span>
            <div class="fs-4">
              <span class="opacity-75"> You have </span>
              <span class="position-relative d-inline-block">
                <router-link
                  to="/intersection"
                  class="opacity-75-hover fw-bold d-block mb-1"
                >
                  {{ intersectionDevices.length }} devices
                </router-link>
                <span
                  class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"
                ></span>
              </span>
            </div>
          </h3>
          <div class="separator separator-dashed my-5"></div>
          <div
            class="table-container"
            style="max-height: 700px; overflow-y: auto"
          >
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="table_accounts_requests"
            >
              <thead>
                <tr
                  class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="">Name</th>
                  <th class="">Loacation</th>
                  <th class="">Status</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr>
                  <td colspan="8">{{ $t("tips.loading") }}</td>
                </tr>
              </tbody>
              <tbody v-if="!isLoading && intersectionDevices.length == 0">
                <tr>
                  <td colspan="8">{{ $t("tips.nodata") }}</td>
                </tr>
              </tbody>
              <tbody
                class="text-gray-600 fw-semibold"
                v-if="!isLoading && intersectionDevices.length > 0"
              >
                <tr v-for="(device, index) in intersectionDevices" :key="index">
                  <td>
                    <router-link
                      :to="{
                        name: 'intersectionDetail',
                        params: { id: device.device_id },
                      }"
                      >{{ device.name }}
                    </router-link>
                  </td>
                  <td>{{ device.location }}</td>
                  <td v-if="device.status == '1'">
                    <div
                      class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Online
                    </div>
                  </td>
                  <td v-if="device.status == '2'">
                    <div
                      class="btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Offline
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, inject } from "vue";

const isLoading = ref(true);
const api: any = inject("api");
const allDevices = ref([]);
const roadwayDevices: any = ref([]);
const intersectionDevices: any = ref([]);

const getDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      } else if (device.type === "1") {
        roadwayDevices.value.push(device);
      }
    });
    isLoading.value = false;
  });
};

onMounted(() => {
  getDevices();
});
</script>
