<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>{{ event.name }}</h2>
      <p>Time: {{ event.time }}</p>
      <div :class="['video-container', { 'single-video': !event.videoUrl2 }]">
        <video
          ref="video1"
          controls
          @canplaythrough="onCanPlayThrough(1)"
          @timeupdate="updateCurrentTime"
        >
          <source :src="event.videoUrl" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video
          v-if="event.videoUrl2"
          ref="video2"
          controls
          @canplaythrough="onCanPlayThrough(2)"
        >
          <source :src="event.videoUrl2" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div
        v-if="event.name == 'Dilemma Zone Detection'"
        class="custom-elements"
      >
        <p>Detection Result</p>
        <TucsonEventVideoMessage
          :tableData="tableData"
          :currentTime="currentTime"
        />
      </div>
      <button :disabled="!playButtonEnabled" @click="togglePlayPause">
        {{ playPauseText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import TucsonEventVideoMessage from "./TucsonEventVideoMessage.vue";
import TucsonDZdata from "./TucsonDZdata.json";

const props = defineProps({
  event: Object,
});

const video1Ready = ref(false);
const video2Ready = ref(!props.event.videoUrl2); // If no second video, it's considered ready
const playButtonEnabled = ref(false);
const isPlaying = ref(false);

const playPauseText = ref("PLAY");

const video1 = ref(null);
const video2 = ref(null);

const tableData = ref(TucsonDZdata);
const currentTime = ref(0);

const updateCurrentTime = () => {
  const video = video1.value;
  if (video) {
    currentTime.value = video.currentTime;
  }
};

const onCanPlayThrough = (videoNumber) => {
  if (videoNumber === 1) {
    video1Ready.value = true;
  } else if (videoNumber === 2) {
    video2Ready.value = true;
  }
};

watch([video1Ready, video2Ready], () => {
  playButtonEnabled.value = video1Ready.value && video2Ready.value;
});

const togglePlayPause = () => {
  if (isPlaying.value) {
    pauseVideos();
  } else {
    playVideos();
  }
  isPlaying.value = !isPlaying.value;
  playPauseText.value = isPlaying.value ? "PAUSE" : "PLAY";
};

const playVideos = () => {
  if (video1.value) {
    video1.value.play();
  }
  if (video2.value) {
    video2.value.play();
  }
};

const pauseVideos = () => {
  if (video1.value) {
    video1.value.pause();
  }
  if (video2.value) {
    video2.value.pause();
  }
};
</script>

<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 90%;
  max-width: 1200px;
  position: relative;
  box-sizing: border-box;
}
.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.video-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  max-width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.video-container.single-video {
  justify-content: center;
}
video {
  flex: 1;
  max-width: 50%;
  box-sizing: border-box;
}
.custom-elements {
  margin-top: 20px;
}
button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
