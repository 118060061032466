import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

const USER_DATA_KEY = "user" as string;

export interface User {
  uid: number;
  name: string;
  surname: string;
  email: string;
  password: string;
  api_token: string;
  avatar_url: string;
  lang: string;
  timezone: string;
  roles: string[];
  permissions: string[];
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  is2fa: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  is2fa = true;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Load local user object
   * @returns User
   */
  get loadUser(): User {
    return Object.assign(
      {},
      JSON.parse(window.localStorage.getItem(USER_DATA_KEY) || "{}")
    );
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get roles(): string[] {
    return this.user.roles;
  }

  get permissions(): string[] {
    return this.user.permissions;
  }
  /**
   * Verify user 2fa
   * @returns boolean
   */
  get isUser2fa(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    console.log("set error", error);
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    JwtService.saveToken(data.access_token);
  }

  @Mutation
  [Mutations.SET_USER](data) {
    this.user = data.data;
    if (!this.user.lang) {
      this.user.lang = "en";
    }
    if (!this.user.timezone) {
      this.user.timezone = "UTC";
    }
    this.is2fa = true;
    window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(this.user));
    window.localStorage.setItem("lang", this.user.lang);
  }

  @Mutation
  [Mutations.SET_USER_LANG](data) {
    this.user.lang = data.data.lang;
    this.is2fa = true;
    window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(this.user));
    window.localStorage.setItem("lang", this.user.lang);
  }

  @Mutation
  [Mutations.SET_USER_AVATAR](data) {
    this.user.avatar_url = data.avatar;
    this.is2fa = true;
    window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(this.user));
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    window.localStorage.removeItem(USER_DATA_KEY);
    JwtService.destroyToken();
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
  }

  @Mutation
  [Mutations.PURGE_TWOFA]() {
    this.is2fa = false;
  }

  @Action
  [Actions.LOGIN](credentials) {
    credentials["client_id"] = process.env.VUE_APP_CLIENT_ID;
    credentials["client_secret"] = process.env.VUE_APP_CLIENT_SECRET;
    credentials["grant_type"] = process.env.VUE_APP_GRANT_TYPE;
    credentials["scope"] = process.env.VUE_APP_SCOPE;
    credentials["username"] = credentials.email;
    return ApiService.post("oauth/token", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        console.log("response", response);
        if (response.data.error != undefined) {
          this.context.commit(Mutations.SET_ERROR, [response.data.error]);
        } else {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.TWOFA](credentials) {
    ApiService.setHeader();
    return ApiService.put("auth/token/verify", credentials)
      .then(() => {
        return;
      })
      .catch(({ response }) => {
        console.log("response", response);
        if (response.data.error != undefined) {
          this.context.commit(Mutations.SET_ERROR, [response.data.error]);
        } else {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("auth/register", credentials)
      .then(() => {
        // this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (this.isAuthenticated) {
      if (this.user.email != undefined) {
        return true;
      }
    }
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return ApiService.get("me")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          return true;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          if (
            response.data.errors &&
            response.data.errors[0] == "__TOKEN_HAS_NOT_BEEN_VALIDATED__"
          ) {
            this.context.commit(Mutations.PURGE_TWOFA);
            return false;
          } else {
            console.log("context: ", this.context);
            this.context.commit(Mutations.PURGE_AUTH);
            return false;
          }
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
      return true;
    }
  }

  @Action
  [Actions.SET_LANG](credentials) {
    ApiService.setHeader();
    return ApiService.put("profile/lang", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_LANG, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.SET_AVATAR](data) {
    this.context.commit(Mutations.SET_USER_AVATAR, data);
  }
}
