<template>
  <div>{{ getDateAndTimeFromISOString() }}</div>
</template>

<script setup lang="ts">
import moment from "moment";
import { useStore } from "vuex";

const props = defineProps<{
  dateIsoString: string;
  format?: string;
}>();

const getDateAndTimeFromISOString = () => {
  const date = moment(props.dateIsoString);

  if (date.year() === 1970 || date.year() === 1969) {
    return "-";
  }

  // const now = moment().locale(useStore().state.AuthModule.user.language);
  // const threeDaysAgo = now.clone().subtract(5, "days");

  // if (date.isBetween(threeDaysAgo, now)) {
  //   return date.fromNow();
  // }

  // return date.locale(useStore().state.AuthModule.user.language).fromNow();
  if (props.format) {
    return date.format(props.format);
  }
  if (date.year() === moment().year()) {
    return date.format("MM-DD HH:mm:ss");
  }
  return date.format("YYYY-MM-DD HH:mm:ss");
};
</script>

<style scoped></style>
