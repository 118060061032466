<template>
  <div class="detectors-container">
    <div class="title">Detectors</div>
    <div class="detectors">
      <div v-for="detector in detectors" :key="detector" class="detector">
        <i class="bi bi-car-front-fill detector-icon"></i>
        <div class="detector-name">{{ detector }}</div>
      </div>
    </div>
    <div class="status">Currently No Data Available</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      detectors: [
        "Detector 1",
        "Detector 2",
        "Detector 3",
        "Detector 4",
        "Detector 5",
        "Detector 6",
        "Detector 7",
        "Detector 8",
      ],
    };
  },
};
</script>

<style scoped>
.detectors-container {
  text-align: center;
  margin-top: 20px;
}
.title {
  font-size: 20px;
  margin-bottom: 10px;
}
.detectors {
  display: flex;
  justify-content: space-between;
}
.detector {
  flex-basis: 12.5%; /* Equal width for each detector */
  text-align: center;
}
.detector-name {
  margin: 5px 0;
  font-size: 1rem;
}
.detector-icon {
  font-size: 2rem; /* Increase the size of the icon */
  margin-bottom: 5px;
}
.status {
  color: gray;
  font-size: 0.8rem;
  bottom: 0;
  left: 0;
  margin: 10px;
}
</style>
