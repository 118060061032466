<template>
  <div class="video-list">
    <h1>Events List</h1>
    <div v-for="video in videos" :key="video.url" class="video-item">
      <h2>{{ video.name }}</h2>
      <p>{{ video.description }}</p>
      <video controls>
        <source :src="video.url" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const videos = ref([
  {
    url: "https://storage.googleapis.com/aiwaysion-test-bucket/demo-video/yakima-counting-demo.mp4",
    name: "Yakima Vehicle Detection",
    description: "Analyze at 2024-03-26 14:35:00",
  },
  {
    url: "https://storage.googleapis.com/aiwaysion-test-bucket/demo-video/yakima_recording_res_new.mp4",
    name: "Yakima Vehicle Counting",
    description: "Analyze at 2024-03-26 14:35:00",
  },
  // Add more videos as needed
]);
</script>

<style scoped>
.video-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.video-item {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

video {
  width: 100%;
  height: auto;
}
</style>
