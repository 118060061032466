<template>
  <div class="card p-5">
    <div class="card-header">
      <h3 class="card-title fw-bold text-dark">Users ({{ detailId }})</h3>
      <div class="card-toolbar">
        <div
          class="btn btn-sm btn-icon btn-active-color-primary"
          @click="$emit('close')"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="/images/icons/arrows/arr061.svg" />
          </span>
        </div>
      </div>
    </div>
    <div class="card-body"></div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
const props = defineProps({
  detailId: { type: Number, required: true },
});
onMounted(() => {
  console.log(props.detailId);
});
</script>
