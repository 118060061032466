<template>
  <div class="main-wrapper mb-5">
    <div class="form-wrapper mb-10">
      <form @submit.prevent="submit" method="POST">
        <h1 class="mb-4">Contact Us</h1>
        <p class="mb-8">
          Got a technical issue? Want to send feedback about our features?
          details about our Business plan? Let us know.
        </p>
        <div v-if="status">
          <h4 class="mb-10 text-center font-weight-bold">
            Submitted successfully!
          </h4>
        </div>
        <div v-else>
          <div class="mb-5">
            <label for="name" class="form-label"> Full Name </label>
            <input
              type="text"
              name="name"
              id="name"
              minlength="2"
              maxlength="20"
              v-model="data.name"
              placeholder="Full Name"
              class="form-control form-control-solid"
            />
          </div>

          <div class="mb-5">
            <label for="email" class="form-label"> Email Address </label>
            <input
              type="email"
              name="email"
              id="email"
              minlength="2"
              maxlength="20"
              v-model="data.email"
              placeholder="Enter your email"
              class="form-control form-control-solid"
            />
          </div>

          <div class="mb-5">
            <label for="message" class="form-label"> Message </label>
            <textarea
              rows="6"
              name="message"
              id="message"
              minlength="10"
              maxlength="200"
              v-model="data.message"
              placeholder="Leave your message"
              class="form-control form-control-solid"
            ></textarea>
          </div>

          <div>
            <button class="btn btn-primary mb-10">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from "vue";

const api = inject("api");

const data = ref({
  name: "",
  email: "",
  message: "",
});

const status = ref(false);

const submit = () => {
  api["contact.contact"]({ data: data.value }).then((res) => {
    if (res.data == "ok") {
      status.value = true;
    }
    console.log(res);
  });
  console.log("submit: ", data.value);
  return false;
};
</script>

<style lang="css">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.mb-4 {
  text-align: center;
  font-weight: bolder;
  font-size: xx-large;
  margin: 10%;
}
.mb-5 {
  margin-bottom: 20px;
}

.mb-8 {
  color: rgb(144, 143, 143);
  text-align: center;
  margin: 5%;
}
.formbold-pt-3 {
  padding-top: 12px;
}
.main-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  margin-left: 15%;
  margin-right: 15%;
  /* padding: 48px; */
}

.form-wrapper {
  margin: 0 auto;
  max-width: 550px;
  width: 50%;
  background: white;
}

.form-label {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #07074d;
  margin-bottom: 12px;
}
.form-label-2 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
</style>
