<template>
  <div>
    <div id="map"></div>
  </div>
  <VideoModal
    v-if="isModalOpen"
    :device="selectedDevice"
    @close="isModalOpen = false"
  ></VideoModal>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import VideoModal from "@/views/intersection/components/MapViewVideoModal.vue";

const props = defineProps<{
  data: any;
}>();

const devices = ref(props.data);
const coordinates: any = [];
const isModalOpen = ref(false);
const selectedDevice = ref({
  name: "",
  ip: "",
  device_id: "",
  updatedAt: "",
});
const centroid = calculateCentroid(devices.value);

const openModal = (deviceName, deviceIP, deviceID, updateAt) => {
  selectedDevice.value.name = deviceName;
  selectedDevice.value.ip = deviceIP;
  selectedDevice.value.device_id = deviceID;
  selectedDevice.value.updatedAt = updateAt;
  isModalOpen.value = true;
  console.log("Selected Device: ", selectedDevice);
};

devices.value.forEach((device) => {
  coordinates.push([device.longitude, device.latitude]);
});

// Calculate the centroid of the devices
function calculateCentroid(devices) {
  let x = 0;
  let y = 0;
  let z = 0;
  for (let i = 0; i < devices.length; i++) {
    const latitude = devices[i].latitude * (Math.PI / 180);
    const longitude = devices[i].longitude * (Math.PI / 180);
    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }
  const total = devices.length;
  x = x / total;
  y = y / total;
  z = z / total;
  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);
  return [
    centralLongitude * (180 / Math.PI),
    centralLatitude * (180 / Math.PI),
  ];
}

function getCameraRotation(description) {
  // Split the string by spaces
  const parts = description.trim().split(" ");
  // Default rotation is 0 (North)
  let rotation = 0;
  // Check if there's a last part that specifies the direction
  const lastPart = parts[parts.length - 1];
  // Determine the rotation based on the last part
  if (lastPart === "N") {
    rotation = 0; // North
  } else if (lastPart === "NE") {
    rotation = 45; // North-East
  } else if (lastPart === "E") {
    rotation = 90; // East
  } else if (lastPart === "SE") {
    rotation = 135; // South-East
  } else if (lastPart === "S") {
    rotation = 180; // South
  } else if (lastPart === "SW") {
    rotation = 225; // South-West
  } else if (lastPart === "W") {
    rotation = 270; // West
  } else if (lastPart === "NW") {
    rotation = 315; // North-West
  }

  return rotation;
}

onMounted(() => {
  console.log("Devices: ", devices);
  mapboxgl.accessToken =
    "pk.eyJ1IjoibHV5YW5nLWFpd2F5c2lvbiIsImEiOiJjbGFyZXhsbHkxc2IzM3JtaXZxejBzamY3In0.FbKMQRkbGUlsTw8KNkxK4Q";
  const map = new mapboxgl.Map({
    container: "map", // container ID
    style: "mapbox://styles/mapbox/streets-v12", // style URL
    zoom: 8, // starting zoom
    center: centroid, // starting position [lng, lat]
  });

  // first coordinate in features
  const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

  // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
  for (const coord of coordinates) {
    bounds.extend(coord);
  }

  map.fitBounds(bounds, {
    padding: 50,
    maxZoom: 18,
  });

  // add zoom in/out
  // const nav = new mapboxgl.NavigationControl({
  //   // visualizePitch: true,
  // });
  // map.addControl(nav, "bottom-right");

  // Change the cursor to a pointer when the it enters a feature in the 'circle' layer.
  map.on("mouseenter", "circle", () => {
    map.getCanvas().style.cursor = "pointer";
  });

  // Change it back to a pointer when it leaves.
  map.on("mouseleave", "circle", () => {
    map.getCanvas().style.cursor = "";
  });

  // add devices markers to map
  for (var i = 0; i < devices.value.length; i++) {
    const el = document.createElement("div");
    el.className = "custom-marker";
    el.style.backgroundImage = "url(/images/camera-icon.png)";
    el.style.width = "35px";
    el.style.height = "50px";
    el.style.backgroundSize = "100%";
    el.dataset.deviceName = devices.value[i].name;
    el.dataset.ip = devices.value[i].ip;
    el.dataset.deviceID = devices.value[i].device_id;
    el.dataset.updatedAt = devices.value[i].updated_at;
    var maker = new mapboxgl.Marker({
      element: el,
      rotation: getCameraRotation(devices.value[i].name),
      offset: [0, -35],
      data: devices.value[i],
    })
      .setLngLat([devices.value[i].longitude, devices.value[i].latitude])
      .addTo(map);
    maker.getElement().addEventListener("click", () => {
      openModal(
        el.dataset.deviceName,
        el.dataset.ip,
        el.dataset.deviceID,
        el.dataset.updatedAt
      );
    });
  }
});
</script>

<style scoped>
#map {
  width: 100%;
  height: 700px;
}
</style>
