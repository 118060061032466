<template>
  <div v-if="isLoading">Loading data</div>
  <div v-else>
    <!--begin::Row-->
    <div class="row mb-5 mb-xl-10 mx-0">
      <div class="col-12 col-lg-4">
        <div class="card p-5">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bold fs-2x mb-3">{{ $t("title.Devices") }}</span>
            <div class="fs-4">
              <span class="opacity-75">{{ $t("tips.youhave") }}</span>
              <span class="position-relative d-inline-block">
                <router-link
                  to="/alldevices"
                  class="opacity-75-hover fw-bold d-block mb-1"
                  >{{ devices.length }} {{ $t("devices.devices") }}</router-link
                >
                <span
                  class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"
                ></span>
              </span>
            </div>
          </h3>
          <div
            class="table-container"
            style="max-height: 500px; overflow-y: auto"
          >
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="table_accounts_requests"
            >
              <thead>
                <tr class="text-start text-muted fw-bold fs-7 text-uppercase">
                  <th class="">N.O</th>
                  <th class="">Name</th>
                  <th class="">Status</th>
                </tr>
              </thead>
              <tbody v-if="isLoading">
                <tr>
                  <td colspan="8">{{ $t("tips.loading") }}</td>
                </tr>
              </tbody>
              <tbody v-if="!isLoading && devices.length == 0">
                <tr>
                  <td colspan="8">{{ $t("tips.nodata") }}</td>
                </tr>
              </tbody>
              <tbody
                class="text-gray-600 fw-semibold"
                v-if="!isLoading && devices.length > 0"
              >
                <tr v-for="(device, index) in devices" :key="index">
                  <td>{{ index }}</td>
                  <td>
                    <router-link
                      :to="{
                        name:
                          device.type == 2
                            ? 'intersectionDetail'
                            : 'deviceDetail',
                        params: { id: device.device_id },
                      }"
                      >{{ device.name }}</router-link
                    >
                  </td>
                  <td v-if="device.status == '1'">
                    <div
                      class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Online
                    </div>
                  </td>
                  <td v-if="device.status == '2'">
                    <div
                      class="btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3"
                    >
                      Offline
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="card card-flush">
          <div
            class="card-header rounded bgi-no-repeat bgi-size-cover bgi-position-y-top bgi-position-x-center align-items-start h-250px"
            style="background-image: url('/images/shapes/top-green.png')"
            data-theme="light"
          >
            <h3
              class="card-title align-items-start flex-column text-white pt-15"
            >
              <span class="fw-bold fs-2x mb-3">{{ $t("title.Devices") }}</span>
              <div class="fs-4 text-white">
                <span class="opacity-75">{{ $t("tips.youhave") }}</span>
                <span class="position-relative d-inline-block">
                  <router-link
                    to="/devices"
                    class="link-white opacity-75-hover fw-bold d-block mb-1"
                    >{{ devices.length }}
                    {{ $t("devices.devices") }}</router-link
                  >
                  <span
                    class="position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100"
                  ></span>
                </span>
              </div>
            </h3>
          </div>
          <div class="card-body mt-n20">
            <div class="mt-n20 position-relative">
              <div class="row g-3 g-lg-6">
                <div class="col-6 col-md-3 col-lg-6">
                  <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                    <div class="symbol symbol-30px me-5 mb-8">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg src="/images/icons/coding/cod007.svg" />
                        </span>
                      </span>
                    </div>
                    <div class="m-0">
                      <span
                        class="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                        >{{ onlineDevices }}</span
                      >
                      <span class="text-gray-500 fw-semibold fs-6">{{
                        $t("status.online")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-6">
                  <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                    <div class="symbol symbol-30px me-5 mb-8">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg src="/images/icons/coding/cod008.svg" />
                        </span>
                      </span>
                    </div>
                    <div class="m-0">
                      <span
                        class="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                        >{{ offlineDevices }}</span
                      >
                      <span class="text-gray-500 fw-semibold fs-6">{{
                        $t("status.offline")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-6">
                  <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                    <div class="symbol symbol-30px me-5 mb-8">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg src="/images/icons/coding/cod009.svg" />
                        </span>
                      </span>
                    </div>
                    <div class="m-0">
                      <span
                        class="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                        >{{ disabledDevices }}</span
                      >
                      <span class="text-gray-500 fw-semibold fs-6">{{
                        $t("status.disabled")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-6 col-md-3 col-lg-6">
                  <div class="bg-gray-100 bg-opacity-70 rounded-2 px-6 py-5">
                    <div class="symbol symbol-30px me-5 mb-8">
                      <span class="symbol-label">
                        <span class="svg-icon svg-icon-1 svg-icon-primary">
                          <inline-svg
                            src="/images/icons/ecommerce/ecm009.svg"
                          />
                        </span>
                      </span>
                    </div>
                    <div class="m-0">
                      <span
                        class="text-gray-700 fw-bolder d-block fs-2qx lh-1 ls-n1 mb-1"
                        >{{ devices.length }}</span
                      >
                      <span class="text-gray-500 fw-semibold fs-6">
                        <router-link to="/alldevices">{{
                          $t("devices.alldevices")
                        }}</router-link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="card card-xl-stretch mt-5 d-none d-lg-block">
          <!--begin::Body-->
          <div
            class="card-body d-flex align-items-center justify-content-between flex-wrap"
          >
            <div class="me-2">
              <h2 class="fw-bold text-gray-800 mb-3">Any question?</h2>
            </div>
            <router-link to="/contact-us" class="btn btn-primary fw-semibold">{{
              $t("title.ContactUs")
            }}</router-link>
          </div>
          <!--end::Body-->
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-8 mt-5 mt-md-0">
        <map-view :data="devices" />
      </div>
    </div>

    <div class="card card-xl-stretch mt-5 d-block d-lg-none">
      <!--begin::Body-->
      <div
        class="card-body d-flex align-items-center justify-content-between flex-wrap"
      >
        <div class="me-2">
          <h2 class="fw-bold text-gray-800 mb-3">Any question?</h2>
        </div>
        <router-link to="/contact-us" class="btn btn-primary fw-semibold">{{
          $t("title.ContactUs")
        }}</router-link>
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>
<script lang="ts">
export default {
  name: "main-dashboard",
};
</script>

<script lang="ts" setup>
import { ref, inject, onMounted } from "vue";
import MapView from "@/views/MapView";

const isLoading = ref(true);
const api = inject("api");
const devices = ref([]);
const onlineDevices = ref(0);
const offlineDevices = ref(0);
const disabledDevices = ref(0);

const allDevicesInfos = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    devices.value = data.data;
    isLoading.value = false;
  });

  // api["devices.getDevices"]().then((data) => {
  //   console.log(data);
  //   data.data.forEach((device) => {
  //     devices.value.push(device);
  //     if (device.status === 1) {
  //       onlineDevices.value++;
  //     } else if (device.status === 0) {
  //       offlineDevices.value++;
  //     } else if (device.status === 2) {
  //       disabledDevices.value++;
  //     }
  //   });
  //   isLoading.value = false;
  // });
};

onMounted(() => {
  allDevicesInfos();
});
</script>
