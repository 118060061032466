import { App } from "vue";
import apiData from "../services/api";
import ApiService from "../services/ApiService";

interface Api {
  apis: Array<(options: object | null) => void>;
  apiBuilder(): void;
  install(app: App): void;
  addApis(_apis: object): void;
}

function createApi(): Api {
  const api: Api = {
    apis: [],
    apiBuilder() {
      Object.keys(apiData).forEach((namespace) => {
        apiData[namespace].forEach((item) => {
          this.apis[namespace + "." + item.name] = (options = {}) => {
            let url = item.url;
            let data = "";
            Object.keys(options).forEach((key) => {
              if (key === "data") {
                data = options[key];
              } else {
                url = url.replace("{" + key + "}", options[key]);
              }
            });
            return ApiService[item.method.toLowerCase()](url, data);
          };
        });
      });
    },
    install(app: App) {
      this.apiBuilder();
      app.config.globalProperties.$api = api.apis;
      app.provide("api", api.apis);
    },
    addApis(_apis: object) {
      Object.keys(_apis).forEach((namespace) => {
        _apis[namespace].forEach((item) => {
          this.apis[namespace + "." + item.name] = (options = {}) => {
            let url = item.url;
            let data = "";
            Object.keys(options).forEach((key) => {
              if (key === "data") {
                data = options[key];
              } else {
                url = url.replace("{" + key + "}", options[key]);
              }
            });
            return ApiService[item.method.toLowerCase()](url, data);
          };
        });
      });
    },
  };
  return api;
}

export default createApi;
