const apis = {
  profile: [
    {
      name: "avatar",
      method: "post",
      url: "/profile/avatar",
      params: {
        avatar: "",
      },
    },
  ],
  devices: [
    {
      name: "getAllDevicesInfo",
      method: "get",
      url: "/devices/all",
    },

    {
      name: "getIntersectionHistory",
      method: "get",
      url: "/intersections/{device_id}/history",
    },

    {
      name: "getDevices",
      method: "get",
      url: "/devices",
    },
    {
      name: "getDeviceDetail",
      method: "get",
      url: "/devices/{device_id}",
    },
    {
      name: "getDeviceHistorical",
      method: "page",
      url: "/devices/{device_id}/historical/{date}",
    },
    {
      name: "getDeviceWeatherFromAPI",
      method: "get",
      url: "/devices/{device_id}/weatherapi",
    },
    {
      name: "getDeviceCurrentImage",
      method: "get",
      url: "/devices/{device_id}/current_image",
    },
    {
      name: "getDeviceTempData",
      method: "get",
      url: "/devices/{device_id}/analytic/temperature",
    },
    {
      name: "getDeviceHumidityData",
      method: "get",
      url: "/devices/{device_id}/analytic/humidity",
    },
    {
      name: "getDeviceSpeedData",
      method: "get",
      url: "/devices/{device_id}/analytic/traffic_speed",
    },
    {
      name: "getDeviceEvents",
      method: "get",
      url: "/devices/{device_id}/events",
    },
    {
      name: "getAllDevices",
      method: "get",
      url: "/super/devices",
    },
    {
      name: "getSuperDeviceDetail",
      method: "get",
      url: "/super/devices/{device_id}",
    },
    {
      name: "createDevice",
      method: "post",
      url: "/super/devices",
      params: {
        name: "",
        device_id: "",
        type: "",
        account_id: "",
        ip: "",
        location: "",
        latitube: "",
        longitube: "",
      },
    },
    {
      name: "updateDevice",
      method: "put",
      url: "/super/devices/{device_id}",
      params: {
        name: "",
        device_id: "",
        type: "",
        account_id: "",
        ip: "",
        location: "",
        latitube: "",
        longitube: "",
      },
    },
    {
      name: "deleteDevice",
      method: "delete",
      url: "/super/devices/{device_id}",
    },
    {
      name: "changeDeviceStatus",
      method: "put",
      url: "/super/devices/{device_id}/status",
      params: {
        status: "",
      },
    },
  ],
  users: [
    {
      name: "getUsers",
      method: "get",
      url: "/super/users",
    },
    {
      name: "getUserDetail",
      method: "get",
      url: "/super/users/{user_id}",
    },
    {
      name: "createUser",
      method: "post",
      url: "/super/users",
      params: {
        name: "",
        email: "",
        password: "",
        role: "",
      },
    },
    {
      name: "updateUser",
      method: "put",
      url: "/super/users/{user_id}",
      params: {
        name: "",
        email: "",
        password: "",
        role: "",
      },
    },
    {
      name: "deleteUser",
      method: "delete",
      url: "/super/users/{user_id}",
    },
  ],
  accounts: [
    {
      name: "getAccounts",
      method: "get",
      url: "/super/accounts",
    },
    {
      name: "getAccountDetail",
      method: "get",
      url: "/super/accounts/{account_id}",
    },
    {
      name: "createAccount",
      method: "post",
      url: "/super/accounts",
      params: {
        name: "",
      },
    },
    {
      name: "updateAccount",
      method: "put",
      url: "/super/accounts/{account_id}",
      params: {
        name: "",
      },
    },
    {
      name: "deleteAccount",
      method: "delete",
      url: "/super/accounts/{account_id}",
    },
  ],

  contact: [
    {
      name: "contact",
      method: "post",
      url: "/contact",
      params: {
        name: "",
        email: "",
        message: "",
      },
    },
  ],
};

export default apis;
