<template>
  <div class="card p-5">
    <div class="card-header">
      <h3 class="card-title fw-bold text-dark">Create new User</h3>
      <div class="card-toolbar">
        <div
          class="btn btn-sm btn-icon btn-active-color-primary"
          @click="$emit('close')"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="/images/icons/arrows/arr061.svg" />
          </span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <el-form
        @submit.prevent="submit()"
        :model="formData"
        :rules="rules"
        ref="formRef"
      >
        <!--begin::Modal body-->
        <div class="py-10 px-lg-17">
          <!--begin::Scroll-->
          <div
            class="scroll-y me-n7 pe-7"
            id="kt_modal_add_customer_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_customer_header"
            data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
            data-kt-scroll-offset="300px"
          >
            <!--begin::Input group-->
            <div class="fv-row mb-7">
              <!--begin::Label-->
              <label class="required fs-6 fw-semobold mb-2">Name</label>
              <!--end::Label-->

              <!--begin::Input-->
              <el-form-item prop="name">
                <el-input v-model="formData.name" type="text" placeholder="" />
              </el-form-item>
              <!--end::Input-->
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="fv-row mb-7">
              <!--begin::Label-->
              <label class="fs-6 fw-semobold mb-2">
                <span class="required">Email</span>

                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Email address must be active"
                ></i>
              </label>
              <!--end::Label-->

              <!--begin::Input-->
              <el-form-item prop="email">
                <el-input v-model="formData.email" />
              </el-form-item>
              <!--end::Input-->
            </div>
          </div>
          <!--end::Scroll-->
        </div>
        <div class="text-right">
          <!--begin::Button-->
          <button
            type="reset"
            id="kt_modal_add_customer_cancel"
            class="btn btn-light me-3"
          >
            Discard
          </button>
          <!--end::Button-->

          <!--begin::Button-->
          <button
            :data-kt-indicator="loading ? 'on' : null"
            class="btn btn-lg btn-primary"
            type="submit"
          >
            <span v-if="!loading" class="indicator-label">
              Submit
              <span class="svg-icon svg-icon-3 ms-2 me-0">
                <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
              </span>
            </span>
            <span v-if="loading" class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Button-->
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
const formData = ref({
  name: "",
  email: "",
});
onMounted(() => {
  console.log("Create new User");
});
</script>
