<template>
  <div class="symbol avatar-bg" :class="avatarClass" v-if="src !== ''">
    <img :src="src" :alt="name" />
  </div>
  <div class="symbol symbol-40px overflow-hidden" v-else>
    <div class="symbol-label fs-3 bg-light-danger" :class="letterAvatarColor">
      {{ avatarLetter }}
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import JwtService from "@/core/services/JwtService";

const props = defineProps({
  avatar: { type: String, required: true },
  name: { type: String, required: true },
  size: { type: String, default: "40px" },
});
const src = ref("");
const avatarLetter = ref("");
const avatarClass = ref("symbol-" + props.size);
const letterAvatarColor = ref("");
// [
//   "bg-light-primary",
//   "bg-light-danger",
//   "bg-light-success",
//   "bg-light-warning",
//   "bg-light-info",
//   "bg-light-dark",
// ];

const processImageUrl = (url: string) => {
  let img_src = "";
  if (url == "no-image") img_src = "/images/no-image.png";
  if (url == "no-avatar") {
    img_src = "";
    avatarLetter.value = props.name.charAt(0).toUpperCase();
    if (["A", "B", "C", "D", "E"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-primary";
    } else if (["F", "G", "H", "I", "J"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-danger";
    } else if (["K", "L", "M", "N", "O"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-success";
    } else if (["P", "Q", "R", "S", "T"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-warning";
    } else if (["U", "V", "W", "X", "Y"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-info";
    } else {
      letterAvatarColor.value = "text-dark";
    }
  } else {
    img_src =
      process.env.VUE_APP_API_URL +
      url +
      "?access_token=" +
      JwtService.getToken();
  }
  return img_src;
};

src.value = processImageUrl(props.avatar);

watch(
  () => props.avatar,
  function () {
    src.value = processImageUrl(props.avatar);
  }
);
</script>

<style lang="css">
.avatar-bg {
  background: url("../../public/images/avatar.png") no-repeat;
  background-size: contain;
}
</style>
