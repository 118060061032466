<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <div class="topbar d-flex align-items-stretch flex-shrink-0">
      <!--begin::Websockets Notity-->
      <!-- <BPWSNotify /> -->
      <!--end::Websockets Notity-->
      <!--begin::User-->
      <div
        class="d-flex align-items-center me-n3 ms-1 ms-lg-3"
        id="kt_header_user_menu_toggle"
      >
        <!--begin::Menu-->
        <div
          class="btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px symbol symbol-35px symbol-md-40px"
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <UserAvatar :avatar="user.avatar_url" :name="user.name" size="40px" />
        </div>
        <KTUserMenu></KTUserMenu>
        <!--end::Menu-->
      </div>
      <!--end::User -->
    </div>
  </div>
  <!--end::Toolbar wrapper-->
</template>
<script lang="ts">
export default {
  name: "layout-topbar",
};
</script>

<script lang="ts" setup>
import { ref } from "vue";
import KTUserMenu from "@/layouts/main-layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
// import BPWSNotify from "@/layouts/main-layout/notify/WSNotify.vue";

const store = useStore();
const user = ref(store.state.AuthModule.user);
</script>
