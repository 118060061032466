import Pusher from "pusher-js";
import { App } from "vue";

interface WSPusher {
  install: (app: App) => void;
  setup: (token: string | null) => void;
  pusher: Pusher | null;
  apiKey: string;
  options: object;
}

function createWSPusher(options): WSPusher {
  const wsPusher: WSPusher = {
    apiKey: options.apiKey,
    pusher: null,
    options: options,
    install: function (app: App) {
      app.provide("wsPusher", this);
    },
    setup: function (token: string | null) {
      if (token) {
        // this.options["channelAuthorization"]["header"] = {
        //   Authorization: "Bearer " + token,
        //   Accept: "application/json",
        // };
        this.options = {
          apiKey: this.apiKey,
          cluster: this.options["cluster"],
          wsHost: this.options["wsHost"],
          wssPort: this.options["wssPort"],
          wsPort: this.options["wsPort"],
          enabledTransports: ["ws", "wss"],
          channelAuthorization: {
            endpoint: this.options["apiurl"],
            headers: {
              Authorization: "Bearer " + token,
              Accept: "application/json",
            },
          },
        };
        this.pusher = new Pusher(this.apiKey, this.options);
      }
    },
  };
  return wsPusher;
}

export { createWSPusher, WSPusher };

// export default (app, { apiKey, ...options }) => {
//   const pusher = new Pusher(apiKey, options);
//   app.config.globalProperties.$pusher = new Pusher(apiKey, options);
//   app.provide("pusher", pusher);
// };
