<template>
  <div>
    <div v-if="isLoading">
      <h1>Loading...</h1>
    </div>
    <div v-else>
      <div class="row">
        <!-- Devices List -->
        <div class="col-3">
          <div class="card">
            <div class="card-header border-0">
              <h3 class="card-title fw-bold text-dark">Devices List</h3>
            </div>
            <div class="card-body pt-2">
              <template v-for="(item, index) in deviceList" :key="index">
                <div class="d-flex align-items-center mb-5">
                  <span
                    class="bullet bullet-vertical h-40px"
                    :class="{
                      'bg-success': item.status == 1,
                      'bg-danger': item.status == 0,
                      'bg-second': item.status == 2,
                    }"
                  ></span>
                  <div
                    class="form-check form-check-custom form-check-solid mx-5"
                  ></div>
                  <div class="flex-grow-1">
                    <router-link
                      :to="'/devices/' + item.device_id"
                      class="text-gray-800 text-hover-primary fw-bold fs-6"
                      @click="switchDevice(item.device_id)"
                      >{{ item.name }}</router-link
                    >
                    <span class="text-muted fw-semobold d-block">{{
                      item.updated_at
                    }}</span>
                  </div>
                  <!--end::Description-->
                  <span class="badge fs-8 fw-bold" :class="`badge-light-`"
                    >New</span
                  >
                </div>
                <!--end:Item-->
              </template>
            </div>
            <!--end::Body-->
          </div>
        </div>
        <!--===================end::Devices List==============================-->

        <!-- ==================begain::Devices details info ==================-->
        <div class="col-9">
          <div class="card">
            <div class="card-body">
              <div class="card mb-5 mb-xl-10">
                <div class="card-body pt-9 pb-0">
                  <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
                    <div class="me-7">
                      <div
                        class="symbol symbol-100px symbol-lg-200px symbol-fixed position-cneter"
                      >
                        <img
                          class=""
                          src="/images/bg/MUST_Device.PNG"
                          alt="Device image"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <div
                        class="d-flex justify-content-between align-items-start flex-wrap mb-2"
                      >
                        <div class="d-flex flex-column">
                          <div class="d-flex align-items-center mb-2">
                            <div class="text-gray-800 fs-2 fw-bold me-1">
                              {{ device.name }}
                            </div>
                            <div
                              v-if="device.status == '1'"
                              class="btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
                            >
                              Online
                            </div>
                            <div
                              v-else
                              class="btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
                            >
                              Offline
                            </div>
                          </div>
                          <div class="d-flex fw-semobold fs-6 mb-2 pe-2 vstack">
                            <div class="d-flex align-items-center me-5 mb-2">
                              Location: {{ device.location }}
                            </div>
                            <div class="d-flex align-items-center me-5 mb-2">
                              Installed at: {{ device.created_at }}
                            </div>
                            <!-- <div class="d-flex align-items-center me-5 mb-2">
                              Last updated at:
                              {{ device.current_env.updated_at }}
                            </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--begin::Tab Nav Header-->
                  <div class="card-header card-header-stretch">
                    <ul
                      class="nav nav-stretch nav-line-tabs fw-semobold border-0"
                      role="tablist"
                      id="kt_layout_builder_tabs"
                      ref="kt_layout_builder_tabs"
                    >
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 0 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="0"
                          href="#kt_builder_overview"
                          role="tab"
                        >
                          Overview
                        </a>
                      </li>
                      <!-- <li v-if="device.status == 1" class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 1 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="1"
                          href="#kt_builder_history"
                          role="tab"
                        >
                          History
                        </a>
                      </li> -->
                      <!-- <li v-if="device.status == 1" class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 2 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="2"
                          href="#kt_builder_analytics"
                          role="tab"
                        >
                          Analytics
                        </a>
                      </li> -->
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 3 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="3"
                          href="#kt_builder_map"
                          role="tab"
                        >
                          Map
                        </a>
                      </li>
                      <li
                        class="nav-item"
                        v-if="
                          device.name == 'Yakama Roadway 1' ||
                          device.name == 'Bellevue Roadway 1'
                        "
                      >
                        <a
                          class="nav-link"
                          :class="{ active: tabIndex === 4 }"
                          data-bs-toggle="tab"
                          @click="setActiveTab($event)"
                          data-tab-index="4"
                          href="#kt_builder_evnet"
                          role="tab"
                        >
                          Event
                        </a>
                      </li>
                    </ul>
                  </div>
                  <!--end::Tab Nav Header-->

                  <!--begin::Tab Body-->
                  <div class="card-body">
                    <div class="tab-content pt-3" id="kt_tabs">
                      <!-- begin::Overview Content-->
                      <div
                        class="tab-pane"
                        :class="{ active: tabIndex === 0 }"
                        id="kt_builder_overview"
                      >
                        <div class="d-flex flex-wrap flex-sm-nowrap mb-3 m-5">
                          <!--begain::Real Time Image-->
                          <div class="col-8 mt-5">
                            <img
                              v-if="device.status != 1"
                              src="/images/no_image_available.jpeg"
                              class="img-fluid"
                              alt="no_image_available"
                            />
                            <!-- v-else-if="image != '' && !showLiveStream" -->
                            <auth-image
                              v-else-if="image != '' && !showLiveStream"
                              :url="image"
                              style="width: 100%"
                            />
                            <!-- v-else-if="showLiveStream" !!!!!!!!!demo only!!!!!!!!!!!!!!-->
                            <video
                              v-show="showLiveStream"
                              id="mse-video"
                              autoplay
                              muted
                              playsinline
                              controls
                              style="max-width: 100%; max-height: 100%"
                            ></video>
                            <!--begain:overlay switch-->
                            <div
                              class="form-check form-switch d-flex justify-content-end mb-3"
                            >
                              <label
                                class="form-check-label fw-bold fs-3 pt-1 text-gray-400"
                                for="LiveStreamToggle"
                              >
                                Iive stream:
                              </label>
                              <input
                                class="form-check-input ms-2 mt-2"
                                type="checkbox"
                                role="switch"
                                id="LiveStreamToggle"
                                unchecked
                                @click="toggleCheckbox"
                              />
                            </div>
                            <!--end:overlay switch !!!!!!demo only !!!!!!!!!!!!-->
                          </div>
                          <!--end::Real Time Image-->

                          <!--begin::Real time Stats-->
                          <div class="col-4">
                            <!--begin::Stats-->
                            <div class="d-flex flex-wrap flex-stack m-5">
                              <!--begin::Wrapper-->
                              <div class="d-flex flex-column flex-grow-1 pe-8">
                                <!--begin::Stats-->
                                <div class="d-flex flex-wrap">
                                  <!--begin::Stat-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded env-data py-3 px-4 mb-3"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-danger me-3"
                                      >
                                        <img
                                          src="/images/icons/env-icon/thermometer.png"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div
                                        v-else
                                        class="d-flex align-items-center"
                                      >
                                        <div class="fs-2">
                                          {{ weather.main.temp }}
                                        </div>
                                        <div class="ms-2">|</div>
                                        <div class="ms-2">
                                          {{
                                            parseFloat(
                                              ((weather.main.temp - 32) * 5) / 9
                                            ).toFixed(2)
                                          }}
                                          °C
                                        </div>
                                      </div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Temperature
                                    </div>
                                    <!--end::Label-->
                                  </div>

                                  <!--begin::Stat-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded env-data py-3 px-4 mb-3"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-danger me-3"
                                      >
                                        <img
                                          src="/images/icons/env-icon/humidity.png"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div v-else class="fs-2">
                                        {{ weather.main.humidity }} %
                                      </div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Humidity
                                    </div>
                                  </div>

                                  <!--begin::Stat-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded env-data py-3 px-4 mb-3"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-danger me-3"
                                      >
                                        <img
                                          src="/images/icons/env-icon/road.png"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div v-else class="fs-2">Dry</div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Road Condition
                                    </div>
                                  </div>

                                  <!--begin::Upstream Traffic Count-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded py-3 px-4 mb-3 env-data"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-success me-3"
                                      >
                                        <inline-svg
                                          src="/images/icons/arrows/arr066.svg"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div v-else class="fs-2">
                                        {{
                                          device.name.startsWith("Bellevue")
                                            ? "580 veh/h"
                                            : device.name.startsWith("Lynnwood")
                                            ? "650 veh/h"
                                            : device.name.startsWith("Yakama")
                                            ? "310 veh/h"
                                            : "No Data"
                                        }}
                                      </div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Upstream Traffic Count (last 15min)
                                    </div>
                                  </div>

                                  <!--begin::Downstream Traffic Count-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded py-3 px-4 mb-3 env-data"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-danger me-3"
                                      >
                                        <inline-svg
                                          src="/images/icons/arrows/arr065.svg"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div v-else class="fs-2">
                                        {{
                                          device.name.startsWith("Bellevue")
                                            ? "620 veh/h"
                                            : device.name.startsWith("Lynnwood")
                                            ? "630 veh/h"
                                            : device.name.startsWith("Yakama")
                                            ? "280 veh/h"
                                            : "No Data"
                                        }}
                                      </div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Downstream Traffic Count (last 15min)
                                    </div>
                                  </div>

                                  <!--begin::Traffic Speed-->
                                  <div
                                    class="border border-gray-500 border-dashed rounded env-data py-3 px-4 mb-3"
                                    v-if="device.type == 1"
                                  >
                                    <!--begin::Number-->
                                    <div class="d-flex align-items-center">
                                      <span
                                        class="svg-icon svg-icon-3 svg-icon-danger me-3"
                                      >
                                        <img
                                          src="/images/icons/env-icon/speedometer.png"
                                        />
                                      </span>
                                      <div v-if="device.status != 1">
                                        No Data
                                      </div>
                                      <div v-else class="fs-2">
                                        {{
                                          device.name.startsWith("Bellevue")
                                            ? "42 mph"
                                            : device.name.startsWith("Lynnwood")
                                            ? "45 mph"
                                            : device.name.startsWith("Yakama")
                                            ? "55 mph"
                                            : "No Data"
                                        }}
                                      </div>
                                    </div>
                                    <!--begin::Label-->
                                    <div class="fw-semobold fs-6 text-gray-500">
                                      Traffic Speed
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--end::Overview Content -->

                      <!--begin::History Content -->
                      <!-- <div
                        v-if="device.status == 1"
                        class="tab-pane"
                        :class="{ active: tabIndex === 1 }"
                        id="kt_builder_history"
                      > -->
                      <!-- <History :device_id="device_id" /> -->
                      <!-- <History /> -->
                      <!-- </div> -->
                      <!--end::History Content -->

                      <!--begin::Analytics Content -->
                      <!-- <div
                        v-if="device.status == 1"
                        class="tab-pane"
                        :class="{ active: tabIndex === 2 }"
                        id="kt_builder_analytics"
                      >
                        <AnalyticTemp
                          v-if="device.type == 1"
                          :device_id="device_id"
                        />
                        <AnalyticHumidity :device_id="device_id" />
                        <AnalyticTrafficSpeed
                          v-if="device.type == 1"
                          :device_id="device_id"
                        />
                      </div> -->
                      <!--end::Analytics Content -->

                      <!--begin::Map Content -->
                      <div
                        v-if="tabIndex === 3"
                        class="col-12 mt-5 mt-md-0"
                        :class="{ active: tabIndex === 3 }"
                        id="kt_builder_map"
                      >
                        <DeviceMap :data="[device]" />
                      </div>
                      <!--end::Map Content -->

                      <!--begin::Event Content-->
                      <div
                        class="tab-pane"
                        :class="{ active: tabIndex === 4 }"
                        id="kt_builder_events"
                        v-if="device.status == 1"
                      >
                        <YakamaEventVideo
                          v-if="device.name == 'Yakama Roadway 1'"
                        />
                        <BellevueEventVideo
                          v-else-if="device.name == 'Bellevue Roadway 1'"
                        />
                      </div>
                      <!--end::Event Content-->
                    </div>
                  </div>
                  <!--end::Tab Body-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ==================end::Devices details info ==================-->
      </div>
      <!-- end::-->
    </div>
  </div>

  <!-- Debug Info -->
</template>

<script lang="ts" setup>
import { ref, onMounted, inject, onUnmounted } from "vue";
// import { WSPusher } from "@/core/plugins/pusher";
// import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import AuthImage from "@/components/AuthImage.vue";
import History from "./HistoryDataYakama.vue";
//import AnalyticTemp from "./AnalyticTemp.vue";
//import AnalyticHumidity from "./AnalyticHumidity.vue";
//import AnalyticTrafficSpeed from "./AnalyticTrafficSpeed.vue";
// import WarningEvent from "@/views/devices/WarningEvent.vue";
import YakamaEventVideo from "./YakamaEventVideo.vue";
import BellevueEventVideo from "./Bellevue1EventVideo.vue";
import DeviceMap from "@/views/devices/DeviceMap.vue";
import StreamsDate from "@/views/intersection/components/StreamsData";

const isLoading = ref(true);
const route = useRoute();
const deviceList = ref([]);
const device_id = ref(route.params.id);
const device = ref();
const api = inject("api");
const image = ref("");
const tabIndex = ref(0);
const weather = ref({});

const switchDevice = async (show_device_id) => {
  if (showLiveStream.value) {
    toggleCheckbox();
  }
  device_id.value = show_device_id;
  getDeviceDetail();
  setActiveTab({ target: { getAttribute: () => 0 } });
};

const getDeviceDetail = async () => {
  api["devices.getDeviceDetail"]({ device_id: device_id.value }).then(
    (data) => {
      device.value = data.data;
      getDeviceCurrentImage();
      getWeatherFromAPI();
      findDeviceStreamUUID(device.value.name);
      // getLatestTrafficData();
      // subscribeDevice();
    }
  );
};

const getDeviceList = async () => {
  api["devices.getDevices"]().then((data) => {
    deviceList.value = data.data;
  });
};

const getDeviceCurrentImage = async () => {
  api["devices.getDeviceCurrentImage"]({ device_id: device_id.value }).then(
    (data) => {
      image.value = "/device/image/" + data.data.image_id;
    }
  );
};

const getWeatherFromAPI = async () => {
  api["devices.getDeviceWeatherFromAPI"]({ device_id: device_id.value }).then(
    (data) => {
      weather.value = data.data;
      isLoading.value = false;
    }
  );
};

/**
 * Set active tab when the tab get clicked
 * @param event
 */
const setActiveTab = (event) => {
  tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
  // keep active tab
  // localStorage.setItem("builderTab", tabIndex.value.toString());
};

const trafficData = ref();
const getLatestTrafficData = async () => {
  try {
    const response = await fetch(`https://aiwaysionapi.com/api/latest-traffic`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    trafficData.value = data;
  } catch (error) {
    console.error("Failed to fetch historical data:", error);
  }
};

onMounted(async () => {
  await getDeviceList();
  await getDeviceDetail();
  tabIndex.value = 0; //parseInt(localStorage.getItem("builderTab") || "0");
});

// when the component is unmounted, check if the live stream is on, if so, stop it
onUnmounted(() => {
  if (showLiveStream.value) {
    stopLiveStream();
  }
});

//just for demo for show live stream !!!!!!!!!!!!!!!!!!!!!!!
const showLiveStream = ref(false);
const streamsData = StreamsDate[0];
const dynamicStreamUrl = ref("");
const mseQueue = [];
let mseSourceBuffer: SourceBuffer;
let mseStreamingStarted = false;
let mse: MediaSource | null = null;
let ws: WebSocket | null = null;

// Toggle live stream
const toggleCheckbox = () => {
  showLiveStream.value = !showLiveStream.value;
  //the the toggle to check or uncheck
  const toggle = document.getElementById(
    "LiveStreamToggle"
  ) as HTMLInputElement;
  toggle.checked = showLiveStream.value;

  if (showLiveStream.value) {
    startPlay(document.querySelector("#mse-video"), dynamicStreamUrl.value);
  } else {
    stopLiveStream();
  }
};

const findDeviceStreamUUID = (deviceName: string) => {
  for (const stream in streamsData) {
    if (streamsData[stream].name === deviceName) {
      dynamicStreamUrl.value = `wss://v.aiwaysion.com/stream/${stream}/channel/0/mse`;
      return;
    }
  }
};

// Stop the live stream
function stopLiveStream() {
  // Immediately stop processing any more incoming messages
  if (ws) {
    ws.onmessage = null; // Remove the message handler
    ws.close();
    ws = null;
  }

  // Check if mse and sourceBuffer exist and are in a state that allows ending the stream
  if (mse && !mseSourceBuffer.updating && mse.readyState === "open") {
    try {
      mse.endOfStream(); // Signal end of stream
    } catch (error) {
      console.error("Error ending media stream:", error);
    }
  }

  mse = null;
  mseSourceBuffer = null; // Clear the source buffer reference

  // Reset the video element
  const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
  if (videoEl) {
    videoEl.pause();
    videoEl.src = "";
    videoEl.load(); // Load with empty source to reset
  }

  // Clear any queued data
  mseQueue.length = 0;
  getDeviceCurrentImage();
  console.log("stoped live stream");
}

function startPlay(videoEl: Element | null, url: string | URL) {
  // Close any existing WebSocket connection
  mse = new MediaSource();
  if (videoEl) {
    const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
    videoEl.src = window.URL.createObjectURL(mse);
  }
  mse.addEventListener(
    "sourceopen",
    function () {
      ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";
      ws.onmessage = function (event) {
        const data = new Uint8Array(event.data);
        if (data[0] === 9) {
          let mimeCodec: string;
          const decodedArr = data.slice(1);
          if (window.TextDecoder) {
            mimeCodec = new TextDecoder("utf-8").decode(decodedArr);
          } else {
            mimeCodec = Utf8ArrayToStr(decodedArr);
          }
          mseSourceBuffer = mse.addSourceBuffer(
            'video/mp4; codecs="' + mimeCodec + '"'
          );
          mseSourceBuffer.mode = "segments";
          mseSourceBuffer.addEventListener("updateend", pushPacket);
        } else {
          readPacket(event.data);
        }
      };
    },
    false
  );
}

function pushPacket() {
  const videoEl = document.querySelector("#mse-video");
  let packet: undefined;

  if (
    mse &&
    mseSourceBuffer &&
    !mseSourceBuffer.updating &&
    mseQueue.length > 0
  ) {
    const packet = mseQueue.shift();
    try {
      mseSourceBuffer.appendBuffer(packet);
    } catch (error) {
      console.error("Error appending buffer:", error);
      // Handle the error, potentially by stopping the stream or alerting the user
    }
  }

  if (!mseSourceBuffer.updating) {
    if (mseQueue.length > 0) {
      packet = mseQueue.shift();
      mseSourceBuffer.appendBuffer(packet);
    } else {
      mseStreamingStarted = false;
    }
  }
  if (videoEl.buffered.length > 0) {
    if (typeof document.hidden !== "undefined" && document.hidden) {
      // no sound, browser paused video without sound in background
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
    }
  }
}

function readPacket(packet: any) {
  if (!mseStreamingStarted) {
    mseSourceBuffer.appendBuffer(packet);
    mseStreamingStarted = true;
    return;
  }
  mseQueue.push(packet);
  if (!mseSourceBuffer.updating) {
    pushPacket();
  }
}

function Utf8ArrayToStr(array: string | any[] | Uint8Array) {
  var out: string, i: number, len: number, c: number;
  var char2: number, char3: number;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 7:
        out += String.fromCharCode(c);
        break;
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}
// just for demo
</script>
<style scoped>
.env-data {
  width: 220px;
}
.env-data img {
  width: 22px;
}
</style>
